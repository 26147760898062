

<template>
  <v-row>
    <v-card center class="v-card" >
      <v-row v-if="desktopView">
        <v-col cols="6">
          <div class="InfoHeading" >Rob Moye</div> 

          <div class="RobInfo">
            <h4>Rob started professionally tattooing in 2008. Growing up in a home full of art, his mother was a key
              inspiration for him to draw.
              His love for doodling beside her evolved into his love for tattoos. The idea of someone carrying his art
              around forever was what really kicked him into gear to be a tattooist.
              Since then, he’s been thriving and perfecting his art with fine line, color, and black & grey being some of
              his specialties. He finds the joy of a new piece for someone as exciting as
              it is for the client themselves. That spark is what drives his creativity and passion for helping his
              clients express themselves!

              During his down time, he crafts personalized Damascus steel knives which they sell at the shop.
              Come by and book an appointment today with Rob!</h4>
          </div></v-col>
          <v-col class="artist" width=80% cols=4 align-self="center">
          <v-img src="Rob1.png" class="artists" height="auto" width="auto" cover></v-img>
            </v-col>
      </v-row>
      <v-row v-if="!desktopView" justify="center" >
        <v-col cols="10">
          <div class="MobileInfoHeading" >Meet Rick Medina</div> 
          
          <v-img src="Rob1.png" class="mx-auto" height="auto" width="auto"></v-img>
            
          <div class="MobileRobInfo">
            <h4>Rick Medina didn’t always have tattooing on his radar. He grew up in Texas and after a rough car accident,
              he took a step back and really gained some perspective about what he wanted out of life. He realized that he
              had a calling to change people’s lives with his art.
              He took a chance and started his apprenticeship at Space Time Tattoos in 2006. Since then, he’s been hard at
              work perfecting and expanding his craft to the highest potential possible.
              He opened up Tried-And-True Tattoos in 2014 and the rest is history. He’s up for any challenge that you can
              throw his way from American Traditional, Old School, Realism, to Watercolor
              and Micro Tattoos. Whatever you have in mind, Rick is the man that can make it happen. Come by or schedule
              an appointment today to see what he can do for you!</h4>
          </div></v-col>
          
      </v-row>
     </v-card>
  </v-row>
<v-card class="blackbackground">
<v-container>
    <v-row>
        <v-col cols="12">
            <div class="text-h4">View His Portfolio</div> 
        </v-col>
    </v-row>
    <v-row>
      <v-carousel show-arrows="hover" hide-delimiter-background>
        <v-carousel-item v-for="(work, i) in works" :key="i" :src="work.src" @click="expandImage(work.src)"></v-carousel-item>
      </v-carousel>
    </v-row>
</v-container>
</v-card>
<v-overlay class="expandedImage" v-model="imageExpand">
 
  
 <img :src="expandedImage">


</v-overlay>
</template>

<script>
export default {

  name: "HelloWorld",

  data: () => ({
    works: [
      { src: "RM picture 10.png" },
      { src: "RM picture 4.png" },
      { src: "RM picture 2.png" },
      { src: "RM picture 6.png" },
      { src: "RM picture 9.png" },
      { src: "RM picture 1.png" },
      { src: "RM Picture 3.png" },
      { src: "RM picture 5.png" },
      { src: "RM picture 7.png" },
      { src: "RM picture 8.png" },
      { src: "RM picture 14.png" },
      { src: "RM picture 15.png" },
    ],
    imageExpand: false,
    expandedImage: '',
    desktopView: true,
  }),
  mounted() {
        if(this.isMobileView()){
          this.desktopView=false;

        }
        else {
          this.desktopView=true;
        }
    },
  methods: {
    isMobileView(){
      var w = window.screen.width;
      console.log(w);
      if (w<650){
        
        return true;
      }
      else {
        
        return false;
      }
    },

    expandImage(image) {
      if(!this.isMobileView()){
        this.imageExpand = true
      this.expandedImage = image
      }
    }

  }
};
</script>

<style scoped>

/* MOBILE VIEW*/


@media all and (max-width: 650px) {

  .InfoHeading{
    font-size: 3px;
    text-align: center;
  }

h4 {
  font-size: 15px;
}
.artist {
  margin: auto;
}
.InfoHeading{
display: block;
}


.RobInfo{
margin: auto;
text-align: center;

}

}

.MobileInfoHeading{
  margin:auto;
  font-size: 45px;
  text-align: center;
  color: #f6a12e;
  padding: auto;
}

.MobileRobInfo {
  margin: 5% auto;
  text-align: center;
}

/* LAPTOP VIEW */ 

@media all and (min-width: 650px) and (max-width: 1800px) {

  h4 {
    font-size: 1.75vw;
  }

  .InfoHeading{
    text-align: center;
  }
  .RobInfo{
    text-align: center;
  }

  .artist {
  margin-left: 2%;
}
}



/* DESKTOP VIEW */ 
.InfoHeading {
  margin-top: 5%;
  margin-left: 16%;
  margin-bottom: 1%;
  font-size: 45px;
  text-align: center;
  color: #f6a12e;
}
.cols {
    -moz-column-count:3;
    -moz-column-gap: 3%;
    -moz-column-width: 30%;
    -webkit-column-count:3;
    -webkit-column-gap: 3%;
    -webkit-column-width: 30%;
    column-count: 3;
    column-gap: 3%;
    column-width: 30%;
}

.expandedImage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.RobInfo {
  margin-left: 16%;
  margin-bottom: 5%;
  font-size: 35px;
}

.text-h4 {
  margin-top: 2%;
  color: #f6a12e;
  }
  
.text-h3{
  font: 1em Dosis;
  margin-top:5%;
  margin-left:16%;
  margin-bottom:1%;
  color:#f6a12e;
}

.text-h4{
  margin-top:2%;
  text-align: center;
  color:#f6a12e;

}

.artist {
  margin-left: 6%;
  margin-top: 3%;
  margin-bottom: 3%;
}




.introPic {
  margin: auto;
  padding-top: 3%;
  padding-left: 10%;
  padding-bottom: 10%;
  min-width: 300px;
  min-height: 300px;
}
.blackbackground {
  background-color: #000000;
}
</style>