<template>
  <v-app>
    <v-main>
        <Contact />
    </v-main>
  </v-app>
</template>

<script>
import Contact from '@/components/Contact'

export default{
  name: "ContactView",

  components: {
    Contact,
  }
}
</script>
