<template>
    <v-snackbar v-model="snackbar" color="secondary" location="top">
        <v-icon icon="mdi-note"></v-icon>
        Request Submitted
        <template v-slot:actions>
            <v-btn variant="text" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>

    <v-img src="Appointments2.jpg" class="img" cover>
            <div class="headline" v-if="!isLoggedIn">REQUEST AN APPOINTMENT</div>
        <div class="headline" v-if="isLoggedIn">SCHEDULE APPOINTMENT</div>
    </v-img>
    <v-app class="backgroundborder">
        <v-container>
            <div v-if="!isLoggedIn">
                <h3 class="text-center">Want to schedule an appointment with us? </h3>
                <h3 class="text-center">Fill out the form below to request an appointment with one of our artists!</h3>
            </div>
            <v-form class="appointment" ref="appform" v-model="valid">
                <v-container>
                    <v-row>
                        <v-col cols='6'>
                            <v-text-field class="fillForm" name="firstname" label="First Name*" id="firstname"
                                v-model="Fname" :rules="nameRules" required>
                            </v-text-field>
                        </v-col>
                        <v-col cols='6'>
                            <v-text-field class="fillForm" name="lastname" label="Last Name*" id="lastname" v-model="Lname"
                                :rules="nameRules" required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='6'>
                            <v-text-field class="fillForm" name="phonenumber" label="Phone Number*" id="phonenumber"
                                v-model="phonenumber" :rules="phoneRules" required>
                            </v-text-field>
                        </v-col>
                        <v-col cols='6'>
                            <v-text-field class="fillForm" name="Email" label="Email*" id="address" v-model="address"
                                :rules="emailRules" required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>

                    </v-row>
                    <v-row>
                        <v-col>
                            <div v-if="!isLoggedIn">
                                Please select an artist and time(s) you would be available, along with your preferred date
                                for the appointment. Please include where you would like the tattoo placed, and if you paid your deposit. <br /><br />
                            </div>
                            <v-select class="fillForm" name="artist" label="Artist*" id="artist" v-model="artist"
                                :rules="selectRules" :items="['Rick Medina', 'Rob Moye', 'Lily McCoy']" required>
                            </v-select>
                            <!--This is so customer can make time slots-->
                            <v-select v-if="!isLoggedIn" class="timeSlot" v-model="timeselected" :items="times" attach chips
                                multiple label="Select Appointment Time(s)*" :rules="selectRules" required></v-select>
                            <v-select v-if="isLoggedIn" class="timeSlot" v-model="selectTime" :items="times"
                                label="Select Appointment Time*" required></v-select>
                            <v-text-field class="fillForm" name="bodyplacement" label="Body Placement" id="bodyplacement"
                                v-model="bodyplacement">
                            </v-text-field>
                            <v-select
                              class="fillForm"
                              name="deposit"
                              label="Have you already paid the deposit?* "
                              id="deposit"
                              v-model="deposit"
                              :rules="selectRules"
                              :items="['I have paid the deposit', 'I have NOT paid the deposit yet']"
                              required>
                            </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                            <vue-cal class="vuecal--date-picker" xsmall hide-view-selector :time="false"
                                :transitions="false" active-view="month" style="width: 270px;height: 300px"
                                :disable-views="['years', 'year', 'week', 'day']" @cell-focus="date = $event"
                                :selected-date="date">
                            </vue-cal>
                      </v-row>
                      <div v-if="deposit === 'I have paid the deposit'&&!isLoggedIn">
                            <p>Please attach the screenshot of CashApp receipt for deposit to Tried & True</p>
                            <v-file-input
                            prepend-icon="mdi-camera"
                            label="Choose a picture"
                            v-on:change="onProofSelected"
                            clearable
                            accept="image/*">
                          </v-file-input>
                      </div>
                    Submit a reference picture of the tattoo you would like

                    <v-row>
                        <v-col cols='12'>

                            <v-file-input prepend-icon="mdi-camera" multiple="true" label="Choose a picture" v-on:change="onImageSelected"
                                accept="image/*"></v-file-input>

                            <!--<input type="file" accept="image/*" @change="onImageSelected">-->
                        </v-col>
                    </v-row>
                    <v-row>
                        <!--This displays image only after image has been upload to firebase storage-->
                        <!--<v-img :src="imageUrl"></v-img>-->
                    </v-row>
                    <v-row>
                        <v-col cols='12'>
                            <v-textarea class="fillForm" name="comments" label="Any other comments" id="comments"
                                v-model="comments">
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-btn color="secondary" class="submitBtn" size="large" :disabled="!valid"
                            @click="submitHandler">Enter</v-btn>
                    </v-row>
                    <!---<p>{{ Fname }}: {{ Lname }}: {{ phonenumber }}: {{ address }}: {{ artist }}: {{ bodyplacement }}: {{ imageUrl }}</p>-->

                </v-container>
            </v-form>



            <div v-if="!isLoggedIn">
                <h3 class="text-center">Once submitted, you will recieve an appointment request confirmation email. </h3>
                <h3 class="text-center"> Our artist will reach out to you regarding their availability with your selected
                    times.</h3>
            </div>
            <div class="text-center">
                <br/>
                    <h3 class="text-h5">All transactions are non-refundable and final.</h3>
                    <h3 class="text-h5"> We have a right to refuse service to anyone.</h3>
                </div>
            <v-dialog v-model="dialog5" width="800px" persistent>
                <v-card>
                    <v-card-title>Enter reply for confirmed appointment: </v-card-title>
                    <v-card-text>
                        <v-container>

                            <v-textarea v-model="reply"></v-textarea>

                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" @click="dialog = false">Close</v-btn>
                        <v-btn color="blue" @click="dialog2 = true">Submit</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
            <v-dialog
          v-model="dialog"
          width="80%"
          persistent
          >
          <v-card>
            <v-card-title>Would you like to add a custom reply?</v-card-title>
              <v-card-text>The default response is:</v-card-text>
              <v-card-text>{{ reply }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" @click="dialog=false">Close</v-btn>
              <v-btn color="red" @click="dialog2=true">No</v-btn>
              <v-btn color="blue" @click="addReply=true">Yes</v-btn>
            </v-card-actions>
            <span v-if="addReply">
            <v-card-title>Enter custom reply for confirmed  appointment: </v-card-title>
            <v-card-text>
              <v-container>

                  <v-textarea
                  v-model="reply"
                  ></v-textarea>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" @click=" dialog= false">Close</v-btn>
              <v-btn color="blue" @click="dialog2=true">Submit</v-btn>
            </v-card-actions>
          </span>
          </v-card>


      </v-dialog>
            <v-dialog v-model="dialog2" persistent>
                <v-card>
                    <v-card-title>Are you sure do you want to submit this appointment?</v-card-title>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" @click="dialog = false, dialog2 = false">Close</v-btn>
                        <v-btn color="blue" @click="confirmOrDeny(appointment), dialog = false, dialog2 = false">Confirm
                            Appointment</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <div class="Form">
                <form id="myForm" ref="form">
                    <!-- possibly incluede date and message -->
                    <input type="text" name="firstname">
                    <input type="text" name="lastname">
                    <input type="email" name="Email">
                    <input type="text" name="message">
                    <input type="text" name="artist">
                    <input type="tel" name="phonenumber">
                    <input type="text" name="date">
                    <input type="text" name="timeselected">
                    <input type="text" name="bodyplacement">
                    <input type="text" name="comments">
                    <input type="text" name="deposit">
                    <button type="submit" onclick="submitForm();return false;">Submit</button>
                </form>
            </div>
        </v-container>
    </v-app>
</template>


<script>

import { db, storage, auth } from '../firebase/config';
import { collection, doc, getDocs, addDoc, deleteDoc, orderBy, query, where, getCountFromServer } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import VueCal from 'vue-cal';

import emailjs from '@emailjs/browser';

// Components
export default {
    components: {
    VueCal
    },
    name: 'Appointment',
    data: () => ({
        Fname: '',
        Lname: '',
        phonenumber: '',
        address: '',
        artist: '',
        bodyplacement: '',
        date: (new Date()),
        timeselected: [],
        nameRules: [
            value => {
                if (value) return true
                return 'Name Required'
            }
        ],
        phoneRules: [
            value => {
                if (!value) return 'Phone number required'
                if (value?.length > 9 && /[0-9-]+/.test(value)) return true
                return 'Invalid phone number'
            }
        ],
        emailRules: [
            value => {
                if (!value) return 'Email required'
                if (/^[a-z.-.0-9]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true
                return 'Must be a valid e-mail.'
            }
        ],
        selectRules: [
            value => {
                if (value) return true
                return 'Selection required'
            }
        ],
      times: [
        '1:00 pm-2:00 pm',
        '2:00 pm-3:00 pm',
        '3:00 pm-4:00 pm',
        '4:00 pm-5:00 pm',
        '5:00 pm-6:00 pm',
        '6:00 pm-7:00 pm',
        '7:00 pm-8:00 pm',
        '8:00 pm-9:00 pm',
        '9:00 pm-10:00 pm',
        '10:00 pm-11:00 pm'
      ],
      selectTime: '',
        comments: '',
        image: null,
        storageRef: [],
        selectedImage: [],
        name: "",
        imageUrl: [],
        isLoggedIn: false,
        dialog: false,
        dialog2: false,
        confirm: false,
        display: false,
        existingApp: '',
        appointment: {},
        newImageName: [],
        selectedProof: null,
        newProofName: '',
        storageProof: null,
        proofUrl: '',
        snackbar: false,
        valid: false,
        deposit: '',
        addReply: false,

    }),

    mounted() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.isLoggedIn = true;
            } else {
                this.isLoggedIn = false;

            }
        })
    },

    methods: {

        reset() {
      location.reload();
        },

        onImageSelected(event) {
        console.log(event.target.files)
        if(event.target.files.length!=0){
        for(var i=0; i<event.target.files.length; i++){
          this.selectedImage.push(event.target.files[i]);
          const submitDate = Date.now()
          this.newImageName.push(submitDate.toString() + this.selectedImage[i].name)
          this.storageRef.push(ref(storage, 'folder/' + this.newImageName[i]));
          console.log(typeof this.selectedImage[i].name)
          console.log(this.selectedImage[i].name)
          console.log(this.newImageName[i])
          console.log(this.selectedImage[i], this.storageRef[i])
        }
      } else {
        this.selectedImage= []
          this.newImageName = []
          this.storageRef= []
          console.log(this.selectedImage.name)
          console.log(this.newImageName)
          console.log(this.selectedImage, this.storageRef)
      }
      },




        async sendToFirebase(Fname, Lname, phonenumber, address, artist, date, timeselected, bodyplacement, comments, image, deposit, proof){
          try {
          const docRef = await addDoc(collection(db, "Appointments"), {
              FirstName: Fname,
              LastName: Lname,
              Phonenumber:phonenumber,
              Address: address,
              Artist: artist,
              Date: date,
              TimeSelected: timeselected,
              Bodyplacement: bodyplacement,
              Comments: comments,
              Image: image,
              Deposit: deposit,
              Proof: proof,
          });
          console.log("Document written with ID: ", docRef.id);
          console.log(docRef.Date)
          this.reset()
          console.log("Working");
          } catch (e) {
          console.error("Error adding document: ", e);
          }
      },
      async sendToBookings(Fname, Lname, phonenumber, address, artist, date, timeselected, bodyplacement, comments, image, deposit, proof){
          try {
          const docRef = await addDoc(collection(db, "Bookings"), {
              FirstName: Fname,
              LastName: Lname,
              Phonenumber:phonenumber,
              Address: address,
              Artist: artist,
              Date: date,
              TimeSelected: timeselected,
              Bodyplacement: bodyplacement,
              Comments: comments,
              Image: image,
              Deposit: deposit,
              Proof: proof
          });
          console.log("Document written with ID: ", docRef.id);
          console.log(docRef.date)
          this.reset()
          console.log("Working");
          } catch (e) {
          console.error("Error adding document: ", e);
          }
      },

      submitForm(Fname, Lname, phonenumber, address, artist, date, timeselected, bodyplacement, comments, message, deposit) {
        var form = document.getElementById("myForm");
        // Set the values of the input fields
        form.elements["firstname"].value = Fname;
        form.elements["lastname"].value = Lname;
        form.elements["Email"].value = address;
        form.elements["artist"].value = artist;
        form.elements["phonenumber"].value = phonenumber;
        form.elements["date"].value = date;
        form.elements["timeselected"].value = timeselected;
        form.elements["bodyplacement"].value = bodyplacement;
        form.elements["comments"].value = comments;
        form.elements["message"].value = message;
        form.elements["deposit"].value = deposit;
        function submitForm(event){
        //Preventing page refresh
        event.preventDefault();
        }
        form.addEventListener('submit', submitForm);
      },

        sendEmail() {
            console.log('called')
            try {
                emailjs.sendForm('service_h5kwe3s', 'template_l9rxbmo', this.$refs.form,
                    'cz2E5MSIY_wnznOZr', {})
                console.log('Email Sent Succesufully')
            }
            catch (error) {
                console.log({ error })
            }
        },

        async submitHandler(){
          if(this.storageProof){
            await this.uploadProof()
            console.log("checking url before firestore", this.proofUrl)
          }
          if(this.storageRef){
            await this.uploadImage()
            console.log("checking url before firestore", this.imageUrl)
          }
          if(this.isLoggedIn){
              console.log("right thing")
              this.appointment = {FirstName:this.Fname, LastName:this.Lname, Phonenumber:this.phonenumber, Address:this.address, Artist:this.artist, Date:this.date.toLocaleDateString('en-US',{ month:"numeric", day:"2-digit", year:"numeric"}), TimeSelected:this.selectTime, Bodyplacement:this.bodyplacement, Comments:this.comments, Image:this.imageUrl, Deposit: this.deposit, Proof: this.proofUrl}
              console.log(this.appointment)
              this.approveItem(this.appointment)
          } else {
              console.log("wrong thing")
              await this.sendToFirebase(this.Fname, this.Lname, this.phonenumber, this.address, this.artist, this.date.toLocaleDateString('en-US',{ month:"numeric", day:"2-digit", year:"numeric"}), this.timeselected, this.bodyplacement, this.comments, this.imageUrl, this.deposit, this.proofUrl);
              this.reset()
          }
          console.log("checking url after firestore", this.imageUrl)

      },

        async approveItem(item) {
            this.reply = "Thank you for your appointment request. Your appointment has been booked."
            console.log('callled approval')

            console.log(item.Artist, item.Date, item.TimeSelected)
            try {
                const coll = collection(db, "Bookings")
                const q = query(coll,

                    where("Artist", "==", item.Artist),
                    where("Date", "==", item.Date),
                    where("TimeSelected", "==", item.TimeSelected),

                );
                console.log(this.existingApp)
                this.existingApp = await getCountFromServer(q)
            } catch (error) {
                console.log("failed finding existing appointment", error)
            }

            if (this.existingApp) {
                console.log(this.existingApp.data().count)

                if (this.existingApp.data().count > 0) {
                    console.log("is this running")
                    const override = confirm("There is already an appointment for this artist at this time. Would you like to add this apppointment anyway? ")
                    if (override) {
                        this.dialog = true
                    }
                } else {
                    this.dialog = true
                }
            } else {
                this.dialog = true
            }

        },


        async confirmOrDeny(item){
        console.log("cofirm this")
        try{
           await this.sendToBookings(item.FirstName, item.LastName, item.Phonenumber, item.Address, item.Artist, item.Date, item.TimeSelected, item.Bodyplacement, item.Comments, item.Image, item.Deposit, item.Proof);
           await this.submitForm(item.FirstName, item.LastName, item.Phonenumber, item.Address, item.Artist, item.Date, item.TimeSelected, item.Bodyplacement, item.Comments, this.reply, item.Deposit)
           console.log("Form subbmited")
           await this.sendEmail()
           this.reset()
        } catch (error) {
          console.error(error)
          return null; }},

      onProofSelected(event) {

          this.selectedProof = event.target.files[0];
          const submitDate = Date.now()
          this.newProofName = submitDate.toString() + this.selectedProof.name
          this.storageProof = ref(storage, 'folder/' + this.newProofName);
          console.log(typeof this.selectedProof.name)
          console.log(this.selectedProof.name)
          console.log(this.newProofName)
          console.log(this.selectedProof, this.storageProof)
      },

      async uploadProof(){
        await uploadBytes(this.storageProof, this.selectedProof)
      .then((snapshot) => {
          console.log("File uploaded successfully!");
      }).catch((error) => {
          console.error("Error uploading file:", error);
      });
      await getDownloadURL(this.storageProof)
      .then((url)=> {
          this.proofUrl = url;
          console.log('proof uploaded successfully:', this.proofUrl);
      })
      .catch((error) => {
          console.error("Error getting download url: ", error)
      });
      },

      async uploadImage() {
      for(var i=0; i<this.storageRef.length; i++){
          await uploadBytes(this.storageRef[i], this.selectedImage[i])
                  .then((snapshot) => {
                      console.log("File uploaded successfully!");
                  }).catch((error) => {
                      console.error("Error uploading file:", error);
                  });
                  await getDownloadURL(this.storageRef[i])
                  .then((url)=> {
                      this.imageUrl.push(url);
                      console.log('Image uploaded successfully:', this.imageUrl[i]);
                  })
                  .catch((error) => {
                      console.error("Error getting download url: ", error)
                  });
                };
              }
    }
  }

</script>

<style scoped>
@media all and (max-width: 450px) {
.headline{
    font-size: 2em !important;
}

}
.headline{
    color: white;
    font: Roboto;
    margin: 40.5% auto 0%;
    font-size: 4em;
    text-align: center;
}

.heading{
    font-size: 4rem !important;
    font-weight: 400;
    line-height: 3.75rem;
    letter-spacing: -0.0083333333em !important;
    text-transform: none !important;

    color: white;
    margin-top: 37%;
    margin-bottom: 1%;
    text-align: center;
}

.Form {
    display: none
}

.backgroundborder {
    border-top: 50%;
    border-left: 0ch;
    border-right: 0ch;
    border-bottom: 0ch;
    border-style: ridge;
    border-color: #f6a12e;
    background-color: #212122;

}
.text-h5{
  color: #f6a12e;
}
</style>



