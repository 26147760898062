<template>
  <v-img src="RobsKnives4.jpg" class="img" width="100%" cover>

    <div class="heading">ROB'S KNIVES</div>

  </v-img>
  <div class="backgroundborder"> </div>
  <v-container fluid>
    <v-row>
      <v-card center class="v-card">
        <v-row v-if="desktopView">
          <v-col cols="">
            <div class="InfoHeading">Custom Handmade Knives</div>
            <div class="KnifeInfo">
              <h4>Starting in 2019, our very own artist Rob Moye started picking up crafting blades as a side hobby from
                all his hard work he does here.
                It was an interest that grew from home as his stepdad often spoke of making knives during his adolescence
                which Rob has made giant strides towards mastering.
                Each knife is uniquely handmade and crafted with his signature logo near the bolster. He’s crafted a
                variety of knives with a diverse selection of steels such as 80crv2, 1084, 15 N 20, and 1095 which
                are all high-carbon steels that are known to be excellent for their strength and endurance. They can vary
                in size from 4 to 7-inch blades being the
                most common and a 16-inch D guard blade being his biggest. From fighter style blades to Damascus style
                blades and even a bowie trench knife,
                his handmade knives are sure to satisfy anyone between an enthusiast to someone just wanting to protect
                themselves. Custom knives are available by request and will vary in price depending on a multitude of
                factors.
                Come by and see what he has on display at the store! </h4>
            </div>
          </v-col>
          <v-col cols="5">
            <v-img class="KnifePic" src="blade4.png" width="93%">
            </v-img>
          </v-col>
        </v-row>
        <v-row v-if="!desktopView">
          <v-col cols="">
            <div class="MobileInfoHeading">Custom Handmade Knives</div>
            <v-img class="MobileKnifePic" src="blade4.png" width="80%"></v-img>
            <div class="MobileKnifeInfo">
              <h4>Starting in 2019, our very own artist Rob Moye started picking up crafting blades as a side hobby from
                all his hard work he does here.
                It was an interest that grew from home as his stepdad often spoke of making knives during his adolescence
                which Rob has made giant strides towards mastering.
                Each knife is uniquely handmade and crafted with his signature logo near the bolster. He’s crafted a
                variety of knives with a diverse selection of steels such as 80crv2, 1084, 15 N 20, and 1095 which
                are all high-carbon steels that are known to be excellent for their strength and endurance. They can vary
                in size from 4 to 7-inch blades being the
                most common and a 16-inch D guard blade being his biggest. From fighter style blades to Damascus style
                blades and even a bowie trench knife,
                his handmade knives are sure to satisfy anyone between an enthusiast to someone just wanting to protect
                themselves. Custom knives are available by request and will vary in price depending on a multitude of
                factors.
                Come by and see what he has on display at the store! </h4>
            </div>
          </v-col>
          
        </v-row>
      </v-card>
    </v-row>
  </v-container>
  <div class="blackbackground">
    <v-container>
      <v-card class="blackbackground">
        <v-card-title>
          <div class="text-h4">View His Collection</div>
        </v-card-title>
        <v-card-text>
          <v-carousel show-arrows="hover" hide-delimiter-background>
            <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" @click="expandImage(item.src)"></v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
  <v-overlay class="expandedImage" v-model="imageExpand">
   
    
   <img :src="expandedImage">
 
</v-overlay>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          src: 'knives3.png',
        },
        {
          src: "knives1.png",
        },
        {
          src: "knives2.png",
        },
        {
          src: "blade5.png",
        },
        {
          src: "blade1.png"
        },
        {
          src: "blade6.jpg",
        }
      ],
      imageExpand:false,
    expandedImage: '',
    
    desktopView: true,
    }
  },
  mounted() {
        if(this.isMobileView()){
          this.desktopView=false;

        }
        else {
          this.desktopView=true;
        }
    },
  methods: {
    isMobileView(){
      var w = window.screen.width;
      console.log(w);
      if (w<800){
        
        return true;
      }
      else {
        
        return false;
      }
    },
    expandImage(image) {
      if(!this.isMobileView()){
        console.log(image);
        this.imageExpand = true;
        this.expandedImage = image;
      }
    } 
  }
}
</script>


<style scoped>

.MobileInfoHeading{
  margin:auto;
  font-size: 45px;
  text-align: center;
  color: #f6a12e;
  padding: auto;
}

.MobileKnifeInfo {
  margin: 5%;
  text-align: center;
}

.MobileKnifePic {
  margin: auto;
}
.KnifeInfo {
  margin-top: 1%;
  margin-left: 10%;
  margin-bottom: 5%;
  font-size: 1.75vw;
}
.expandedImage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.InfoHeading {
  color: #f6a12e;
  margin-top: 2%;
  text-align: center;
  font-weight: 400;
  font-size: 2.5vw;
}

 .text-h2{
    color: white;
    font:  Dosis;
    margin-top: 40.5%;
    margin-right: 3%;
    text-align: center;
    font-weight: 400;
  }

.KnifeInfo{
    margin-left: 10%;
    margin-top: 1%;
    margin-bottom: 5%;
    font-size: 1.4vw;
}
.text-h3{
    color: #f6a12e;
    font:  Dosis;
    margin-top: 2%;
    margin-right: 29%;
    text-align: center;
    font-weight: 400;
}
.text-h4{

  margin-top: 1%;
  text-align: center;
  color: #f6a12e;
}

.backgroundborder {
  border-top: 50%;
  border-left: 0ch;
  border-right: 0ch;
  border-bottom: 0ch;
  border-style: ridge;
  border-color: #f6a12e;
  background-color: #212122;
}

.blackbackground {
  background-color: #000000;
}

.KnifePic {
  margin-top: 15%;
  margin-bottom: 2%;
  margin-left: auto;
  margin-right: auto;
}

</style>