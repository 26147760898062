// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

const myCustomTheme = {
    dark: true,
    colors: {
        background: '#212121',
        primary: "#000000",
        secondary: '#f6a12e',
        info: '#5cffe5'
    }
}

export default createVuetify({
    theme: {
        defaultTheme: 'myCustomTheme', 
        themes: {
            myCustomTheme
        }
    }
});