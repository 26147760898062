<template>
    <v-app>
      <v-main>
        <Newsfeed/>
      </v-main>
    </v-app>
  </template>
  
  <script>
  // Components
  import Newsfeed from '@/components/NewsFeed'
  
  export default {
    name: "NewsView",
  
    components: {
        Newsfeed
    },
  };
  </script>