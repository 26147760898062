<template>
  <v-app>
    <v-main>
      <HelloWorld />
    </v-main>
  </v-app>
</template>

<script>
// Components
import HelloWorld from '@/components/HelloWorld'

export default {
  name: "HomeView",

  components: {
    HelloWorld,
  },
};
</script>
