<template>
  <v-app>
    <v-main>
        <ConfirmationList />
    </v-main>
  </v-app>
</template>

<script>
import ConfirmationList from '@/components/ConfirmationList.vue'

export default{
  name: "ConfirmationView",

  components: {
    ConfirmationList,
  }
}
</script>
