<template>

  <div class="headline">APPOINTMENTS REQUEST</div>

 <v-app>
   <v-card>
     <v-tabs v-model="tab" color="secondary" grow bg-color="primary">

       <v-tab value="Cal">Calendars</v-tab>
       <v-tab value="AllRequests">Requests</v-tab>
       </v-tabs>

     <v-card-text>
       <v-window v-model="tab">

       <v-window-item value="AllRequests">
         <v-card>
         <v-tabs v-model="requests" color="info" grow bg-color="primary">
           <v-tab value="AllReq">All Requests</v-tab>
           <v-tab value="Rick">Rick</v-tab>
           <v-tab value="Rob">Rob</v-tab>
           <v-tab value="Lily">Lily</v-tab>
         </v-tabs>
         <v-card-text>
           <v-window v-model="requests" >
             <!-- All Requests -->
             <v-window-item value="AllReq" v-for="doc in documents" :key="doc.id">
               <v-container max-width="500" class="space">
                   <v-card class="card" variant="outlined" max-width="600" fluid center>
                             <v-card-text>
                               <div>
                                 <div class="alignListItem">
                                   <div class="container">
                                     <ul>

                                       <li>
                                         <span class="label">First Name:</span>
                                         <span class="value">{{ doc.FirstName }}</span>
                                       </li>
                                       <li>
                                         <span class="label">Last Name:</span>
                                         <span class="value">{{ doc.LastName }}</span>
                                       </li>
                                       <li>
                                         <span class="label">Artist Name:</span>
                                         <span class="value">{{ doc.Artist }}</span>
                                       </li>
                                       <li>
                                         <span class="label">Phone Number:</span>
                                         <span class="value">{{ doc.Phonenumber }}</span>
                                       </li>
                                       <li>
                                         <span class="label">Email Address:</span>
                                         <span class="value">{{ doc.Address }}</span>
                                       </li>
                                       <li>
                                         <span class="label">Date:</span>
                                         <span class="value">{{ doc.Date }}</span>
                                       </li>
                                       <li>
                                         <span class="label">Time Selected:</span>
                                         <v-select
                                         class="fillForm"
                                           name="timeSelected"
                                           label="Select Time"
                                           id="timeSelected"
                                           v-model="doc.timeSelected"
                                           :items="doc.TimeSelected.sort()"
                                           density = "compact"
                                           variant = "underlined"
                                           required>
                                         </v-select>
                                       </li>
                                       <li v-if="doc.Bodyplacement">
                                         <span class="label">Body Placement:</span>
                                         <span class="value">{{ doc.Bodyplacement }}</span>
                                       </li>
                                       <li v-if="doc.Deposit">
                                         <span class="label">Deposit Status:</span>
                                         <span class="value">{{ doc.Deposit }}</span>
                                       </li>
                                       <li v-if="doc.Deposit === 'I have paid the deposit'">
                                         <span class="label">Proof of Deposit:</span>
                                         <div v-if="doc.Proof" class="list-item-buttons">
                                           <button class="displayProof" @click="doc.displayProof = doc.displayProof ? false : true">View Proof</button>
                                         </div>
                                         <span v-else class="value">None</span>
                                         
                                       </li>
                                       <li>
                                         <v-img v-if="doc.displayProof" :src="doc.Proof"></v-img>
                                       </li>
                                       <li v-if="doc.Comments">
                                         <span class="label">Comments:</span>
                                         <span class="value">{{ doc.Comments }}</span>
                                       </li>
                                       <li v-if="doc.Image[0]">
                                         <div class="list-item-buttons">
                                           <button class="displayImage" @click="doc.displayImage = doc.displayImage ? false : true">View Image</button>
                                         </div>
                                       </li>
                                       <span v-if="doc.displayImage">
                                         
                                         <li v-for="images in doc.Image" :key="images">
                                           
                                             <v-img  :src="images"></v-img>
                                           
                                         </li>
                                       </span>
                                     </ul>
                                     <div class="buttons">
                                       <div class="list-item-buttons">
                                         <button class="approve" @click="approveItem(doc)">Approve</button>
                                       </div>
                                       <div class="list-item-buttons">
                                         <button class="deny" @click="denyItem(doc)">Deny</button>
                                       </div>
                                     </div>
                                   </div>


                                 </div>

                               </div>
                             </v-card-text>
                   </v-card>
               </v-container>
             </v-window-item>
             <!-- Rick Requests -->
             <v-window-item value="Rick" v-for="doc in documents" :key="doc.id">
               <v-container v-if="doc.Artist === 'Rick Medina'" max-width="500" class="space">
                 <v-card variant="outlined" max-width="600" >
                   <v-card-text>
                 <div>
                   <div class="alignListItem">
                     <div class="container">
                       <ul>
                         <li>
                           <span class="label">First Name:</span>
                           <span class="value">{{ doc.FirstName }}</span>
                         </li>
                         <li>
                           <span class="label">Last Name:</span>
                           <span class="value">{{ doc.LastName }}</span>
                         </li>
                         <li>
                           <span class="label">Artist Name:</span>
                           <span class="value">{{ doc.Artist }}</span>
                         </li>
                         <li>
                           <span class="label">Phone Number:</span>
                           <span class="value">{{ doc.Phonenumber }}</span>
                         </li>
                         <li>
                           <span class="label">Email Address:</span>
                           <span class="value">{{ doc.Address }}</span>
                         </li>
                         <li>
                           <span class="label">Date:</span>
                           <span class="value">{{ doc.Date }}</span>
                         </li>
                         <li>
                           <span class="label">Time Selected:</span>
                           <v-select
                           class="fillForm"
                             name="timeSelected"
                             label="Select Time"
                             id="timeSelected"
                             v-model="doc.timeSelected"
                             :items="doc.TimeSelected.sort()"
                             density = "compact"
                             variant = "underlined"
                             required>
                           </v-select>
                         </li>
                         <li v-if="doc.Bodyplacement">
                           <span class="label">Body Placement:</span>
                           <span class="value">{{ doc.Bodyplacement }}</span>
                         </li>
                         <li v-if="doc.Deposit">
                           <span class="label">Deposit Status:</span>
                           <span class="value">{{ doc.Deposit }}</span>
                         </li>
                         <li v-if="doc.Deposit === 'I have paid the deposit'">
                           <span class="label">Proof of Deposit:</span>
                           <div v-if="doc.Proof" class="list-item-buttons">
                             <button class="displayProof" @click="doc.displayProof = doc.displayProof ? false : true">View Proof</button>
                           </div>
                           <span v-else class="value">None</span>
                           
                         </li>
                         <li>
                           <v-img v-if="doc.displayProof" :src="doc.Proof"></v-img>
                         </li>
                         <li v-if="doc.Comments">
                           <span class="label">Comments:</span>
                           <span class="value">{{ doc.Comments }}</span>
                         </li>
                         <li v-if="doc.Image[0]">
                           <div class="list-item-buttons">
                             <button class="displayImage" @click="doc.displayImage = doc.displayImage ? false : true">View Image</button>
                           </div>
                         </li>
                         <span v-if="doc.displayImage">
                           
                           <li v-for="images in doc.Image" :key="images">
                             
                               <v-img  :src="images"></v-img>
                             
                           </li>
                         </span>
                         
                       </ul>
                       <div class="buttons">
                         <div class="list-item-buttons">
                           <button class="approve" @click="approveItem(doc)">Approve</button>
                         </div>
                         <div class="list-item-buttons">
                           <button class="deny" @click="denyItem(doc)">Deny</button>
                         </div>
                       </div>
                       </div>
                       </div>
                       
                 </div>
               </v-card-text>

                 </v-card>
               </v-container>
         </v-window-item>
         <!-- Rob Requests -->
         <v-window-item value="Rob" v-for="doc in documents" :key="doc.id">
           <v-container v-if="doc.Artist === 'Rob Moye'" max-width="500" class="space">
             <v-card variant="outlined" max-width="600">
               <v-card-text>
             <div>
               <div class="alignListItem">
                 <div class="container">
                   <ul>
                     <li>
                       <span class="label">First Name:</span>
                       <span class="value">{{ doc.FirstName }}</span>
                     </li>
                     <li>
                       <span class="label">Last Name:</span>
                       <span class="value">{{ doc.LastName }}</span>
                     </li>
                     <li>
                       <span class="label">Artist Name:</span>
                       <span class="value">{{ doc.Artist }}</span>
                     </li>
                     <li>
                       <span class="label">Phone Number:</span>
                       <span class="value">{{ doc.Phonenumber }}</span>
                     </li>
                     <li>
                       <span class="label">Email Address:</span>
                       <span class="value">{{ doc.Address }}</span>
                     </li>
                     <li>
                       <span class="label">Date:</span>
                       <span class="value">{{ doc.Date }}</span>
                     </li>
                     <li>
                       <span class="label">Time Selected:</span>
                       <v-select
                       class="fillForm"
                         name="timeSelected"
                         label="Select Time"
                         id="timeSelected"
                         v-model="doc.timeSelected"
                         :items="doc.TimeSelected.sort()"
                         density = "compact"
                         variant = "underlined"
                         required>
                       </v-select>
                     </li>
                     <li v-if="doc.Bodyplacement">
                       <span class="label">Body Placement:</span>
                       <span class="value">{{ doc.Bodyplacement }}</span>
                     </li>
                     <li v-if="doc.Deposit">
                       <span class="label">Deposit Status:</span>
                       <span class="value">{{ doc.Deposit }}</span>
                     </li>
                     <li v-if="doc.Deposit === 'I have paid the deposit'">
                       <span class="label">Proof of Deposit:</span>
                       <div v-if="doc.Proof" class="list-item-buttons">
                         <button class="displayProof" @click="doc.displayProof = doc.displayProof ? false : true">View Proof</button>
                       </div>
                       <span v-else class="value">None</span>
                       
                     </li>
                     <li>
                       <v-img v-if="doc.displayProof" :src="doc.Proof"></v-img>
                     </li>
                     <li v-if="doc.Comments">
                       <span class="label">Comments:</span>
                       <span class="value">{{ doc.Comments }}</span>
                     </li>
                     <li v-if="doc.Image[0]">
                       <div class="list-item-buttons">
                         <button class="displayImage" @click="doc.displayImage = doc.displayImage ? false : true">View Image</button>
                       </div>
                     </li>
                     <span v-if="doc.displayImage">
                       
                       <li v-for="images in doc.Image" :key="images">
                         
                           <v-img  :src="images"></v-img>
                         
                       </li>
                     </span>
                   </ul>
                   <div class="buttons">
                     <div class="list-item-buttons">
                       <button class="approve" @click="approveItem(doc)">Approve</button>
                     </div>
                     <div class="list-item-buttons">
                       <button class="deny" @click="denyItem(doc)">Deny</button>
                     </div>
                   </div>
                   </div>
               </div>

             </div>
           </v-card-text>

             </v-card>
           </v-container>
         </v-window-item>
             <!-- Lily Requests -->
             <v-window-item value="Lily" v-for="doc in documents" :key="doc.id">
               <v-container v-if="doc.Artist === 'Lily McCoy'" max-width="500" class="space">
               <v-card variant="outlined" max-width="600" >
                 <v-card-text>
                 <div>
                   <div class="alignListItem">
                     <div class="container">
                       <ul>
                         <li>
                           <span class="label">First Name:</span>
                           <span class="value">{{ doc.FirstName }}</span>
                         </li>
                         <li>
                           <span class="label">Last Name:</span>
                           <span class="value">{{ doc.LastName }}</span>
                         </li>
                         <li>
                           <span class="label">Artist Name:</span>
                           <span class="value">{{ doc.Artist }}</span>
                         </li>
                         <li>
                           <span class="label">Phone Number:</span>
                           <span class="value">{{ doc.Phonenumber }}</span>
                         </li>
                         <li>
                           <span class="label">Email Address:</span>
                           <span class="value">{{ doc.Address }}</span>
                         </li>
                         <li>
                           <span class="label">Date:</span>
                           <span class="value">{{ doc.Date }}</span>
                         </li>
                         <li>
                           <span class="label">Time Selected:</span>
                           <v-select
                           class="fillForm"
                             name="timeSelected"
                             label="Select Time"
                             id="timeSelected"
                             v-model="doc.timeSelected"
                             :items="doc.TimeSelected.sort()"
                             density = "compact"
                             variant = "underlined"
                             required>
                           </v-select>
                         </li>
                         <li v-if="doc.Bodyplacement">
                           <span class="label">Body Placement:</span>
                           <span class="value">{{ doc.Bodyplacement }}</span>
                         </li>
                         <li v-if="doc.Deposit">
                           <span class="label">Deposit Status:</span>
                           <span class="value">{{ doc.Deposit }}</span>
                         </li>
                         <li v-if="doc.Deposit === 'I have paid the deposit'">
                           <span class="label">Proof of Deposit:</span>
                           <div v-if="doc.Proof" class="list-item-buttons">
                             <button class="displayProof" @click="doc.displayProof = doc.displayProof ? false : true">View Proof</button>
                           </div>
                           <span v-else class="value">None</span>
                           
                         </li>
                         <li>
                           <v-img v-if="doc.displayProof" :src="doc.Proof"></v-img>
                         </li>
                         <li v-if="doc.Comments">
                           <span class="label">Comments:</span>
                           <span class="value">{{ doc.Comments }}</span>
                         </li>
                         <li v-if="doc.Image[0]">
                           <div class="list-item-buttons">
                             <button class="displayImage" @click="doc.displayImage = doc.displayImage ? false : true">View Image</button>
                           </div>
                         </li>
                         <span v-if="doc.displayImage">
                           
                           <li v-for="images in doc.Image" :key="images">
                             
                               <v-img  :src="images"></v-img>
                             
                           </li>
                         </span>
                       </ul>
                       <div class="buttons">
                         <div class="list-item-buttons">
                           <button class="approve" @click="approveItem(doc)">Approve</button>
                         </div>
                         <div class="list-item-buttons">
                           <button class="deny" @click="denyItem(doc)">Deny</button>
                         </div>
                       </div>
                     </div>



                   </div>

                 </div>
               </v-card-text>
               </v-card>
               </v-container>
             </v-window-item>
           </v-window>
         </v-card-text>
         </v-card>
       
     </v-window-item>


         <v-window-item value="Cal">
             <v-card>
              <h2> Select a Calendar View </h2>
              <br/>
             <v-tabs v-model="tabCal" color="info" grow bg-color="primary">
               <v-tab value="AllCal">All Calendars</v-tab>
               <v-tab value="RickCal">Rick</v-tab>
               <v-tab value="RobCal">Rob</v-tab>
               <v-tab value="LilyCal">Lily</v-tab>
             </v-tabs>

               <v-card-text>
                 <v-window v-model="tabCal">
                   <v-window-item value="AllCal">
                     <vue-cal
                       :selected-date="date"
                       :time-from="10 * 60"
                       :disable-views="['years', 'year']"
                       active-view="month"
                       events-on-month-view="short"
                       :events="eventsData"
                       :on-event-click="onEventClick"
                       style="height: 600px">
                     </vue-cal>
                 </v-window-item>
                   <v-window-item value="RickCal">
                     <vue-cal
                       :selected-date="date"
                       :time-from="10 * 60"
                       :disable-views="['years', 'year']"
                       active-view="month"
                       events-on-month-view="short"
                       :events="rickEvents"
                       :on-event-click="onEventClick"
                       style="height: 600px">
                     </vue-cal>
                   </v-window-item>
                   <v-window-item value="RobCal">
                     <vue-cal
                       :selected-date="date"
                       :time-from="10 * 60"
                       :disable-views="['years', 'year']"
                       active-view="month"
                       events-on-month-view="short"
                       :events="robEvents"
                       :on-event-click="onEventClick"
                       style="height: 600px">
                     </vue-cal>
                   </v-window-item>
                   <v-window-item value="LilyCal">
                     <vue-cal
                       :selected-date="date"
                       :time-from="10 * 60"
                       :disable-views="['years', 'year']"
                       active-view="month"
                       events-on-month-view="short"
                       :events="lilyEvents"
                       :on-event-click="onEventClick"
                       style="height: 600px">
                     </vue-cal>
                   </v-window-item>
                 </v-window>
               </v-card-text>

             </v-card>
          
         </v-window-item>

       </v-window>
     </v-card-text>



   </v-card>

   <div class="Form">
             <form
             id="myForm"
             ref="form"
             >
               <!-- possibly incluede date and message -->
               <input type="text" name="firstname">
               <input type="text" name="lastname">
               <input type="email" name="Email">
               <input type="text" name="message">
               <input type="text" name="artist">
               <input type="tel" name="phonenumber">
               <input type="text" name="date">
               <input type="text" name="timeselected">
               <input type="text" name="bodyplacement">
               <input type="text" name="deposit">
               <input type="text" name="comments">
               <button type="submit" onclick="submitForm();return false;">Submit</button>
             </form>
           </div>
</v-app>


<v-dialog
 v-model="adminDialog"
 width="50%"
 persistent>
 <v-card>
 <v-card-title>Would you like to add any admin comments for later?</v-card-title>
           
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="red" @click="adminDialog=false">Close</v-btn>
           <v-btn color="red" @click="dialog=true">No</v-btn>
           <v-btn color="blue" @click="addAdmin=true">Yes</v-btn>
         </v-card-actions>
         <span v-if="addAdmin">
           <v-card-title>Admin Comments: </v-card-title>
           <v-card-text>
           <v-container>

               <v-textarea
               v-model="adminComments"
               ></v-textarea>

           </v-container>
         </v-card-text>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="red" @click="adminDialog=false">Close</v-btn>
           <v-btn color="blue" @click="dialog=true">Submit</v-btn>
         </v-card-actions>
         </span>
       </v-card>
</v-dialog>


       
       <v-dialog v-model="calDialog" width="60%" class="calendarInfo">
         <v-card fluid class="pa-4">
           <v-row>
             <v-col>
         <v-card-title class="text-wrap">
           <span> Client: {{selectedEvent.title}} </span><v-spacer></v-spacer>
           <span> Client E-Mail: {{ selectedEvent.address }}</span>
           <v-spacer>Appointment Date: {{ selectedEvent.start  && selectedEvent.start.format('MM/DD/YYYY') }}</v-spacer>
           </v-card-title>
         </v-col>
           </v-row>
           
           <v-row>
             <v-col style="min-width:150px">
           <v-card-text>
             <strong>Appointment Details: </strong> <br/>
             <ul>
               <li>Artist: {{selectedEvent.artist}}</li>
               <li>Appointment Starts At: {{ selectedEvent.start && selectedEvent.start.formatTime()}} </li>
               <li>Appointment Ends At: {{ selectedEvent.end && selectedEvent.end.formatTime()}}</li>
               <li>Customer Comments: {{ selectedEvent.content}} </li>
               <li>
                 <!-- <div>
                   <v-textarea label="Enter Artist Comments:" v-model="adminData"></v-textarea>
                 </div> -->
               </li>
               <li>Deposit: {{ selectedEvent.deposit}} </li>
               <li v-if="selectedEvent.Deposit === 'I have paid the deposit'">
                       <span class="label">Proof of Deposit:</span>
                       <div v-if="selectedEvent.Proof" class="list-item-buttons">
                         <button class="displayProof" @click="selectedEvent.displayProof = doc.displayProof ? false : true">View Proof</button>
                       </div>
                       <span v-else class="value">None</span>
               </li>
                <li v-if="selectedEvent.Image[0]">
                       <div class="list-item-buttons">
                         <button class="displayImage" @click="selectedEvent.displayImage = selectedEvent.displayImage ? false : true">View Image</button>
                       </div>
                     </li>
                     <span v-if="selectedEvent.displayImage">
                       <li v-for="images in selectedEvent.Image" :key="images">
                           <v-img  :src="images"></v-img>
                       </li></span>
                       </ul>
                       </v-card-text>
                     </v-col><v-col style="min-width:250x">
                       <v-card-text>
                         <ul>
                       <li v-if="selectedEvent.adminComments">Admin Comments: {{ selectedEvent.adminComments }}</li>
                     <li>
                       <div class="list-item-buttons">
                       <button class="adminComments" @click="selectedEvent.addAdmin = selectedEvent.addAdmin ? false : true">Add/Edit Admin Comments</button>
                     </div>
                   </li>
                       <v-row v-if="selectedEvent.addAdmin">
                         <v-col style="min-width:250x">
                         <v-card-title class="text-wrap">Add/edit admin comments: </v-card-title>
                       </v-col>
                       <v-col  style="min-width:150px">
                         <v-card-text>
                           

                               <v-textarea
                               v-model="adminComment"
                               @keydown.enter="updateAdminComment(selectedEvent)"
                               ></v-textarea>

                           
                         </v-card-text>
                       </v-col><v-col style="min-width:150px">
                         <v-card-actions>
                           <v-btn color="blue" @click="updateAdminComment(selectedEvent)">Submit Comment</v-btn>
                         </v-card-actions>
                       </v-col>
                       </v-row>
                     <li>
                       <div class="list-item-buttons">
                       <button color='red' class="deleteAppointment" @click="openDeleteDialogBox(selectedEvent)">Delete Appointment</button>
                     </div>
                     </li>
             </ul>
             
           </v-card-text>
         </v-col>
             </v-row>
         </v-card>
       </v-dialog>
       <v-dialog
         v-model="appointmentDelete"
         width="80%"
         persistent>
         <v-card>
         <v-card-title>Do you want to notify the customer of the cancellation?</v-card-title>

           <v-card-actions>
             <v-spacer></v-spacer>
             <v-btn color="red"  @click="appointmentDelete=false">Close</v-btn>
             <v-btn color="red" @click="notifyCustomer=false, deleteItem()">No</v-btn>
             <v-btn color="blue" @click="notifyCustomer=true, deleteItem() ">Yes</v-btn>
           </v-card-actions>
         </v-card>
   
       </v-dialog>
       <v-dialog
       v-model="dialog"
       width="80%"
       persistent
       >
       <v-card>
         <v-card-title>Would you like to add a custom reply?</v-card-title>
           <v-card-text>The default response is:</v-card-text>
           <v-card-text>{{ reply }}</v-card-text>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="red" @click="dialog=false, adminDialog=false">Close</v-btn>
           <v-btn color="red" @click="dialog2=true">No</v-btn>
           <v-btn color="blue" @click="addReply=true">Yes</v-btn>
         </v-card-actions>
         <span v-if="addReply">
         <v-card-title>Enter custom reply for {{ appStatus }} appointment: </v-card-title>
         <v-card-text>
           <v-container>

               <v-textarea
               v-model="reply"
               ></v-textarea>

           </v-container>
         </v-card-text>

         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="red" @click=" dialog= false">Close</v-btn>
           <v-btn color="blue" @click="dialog2=true">Submit</v-btn>
         </v-card-actions>
       </span>
       </v-card>


   </v-dialog>
   <v-dialog
     v-model="dialog2"
     width="80%"
     persistent
   >
     <v-card>
         <v-card-title>{{ conf }}</v-card-title>

           <v-card-actions>
             <v-spacer></v-spacer>
             <v-btn color="red"  @click="dialog=false,dialog2= false, adminDialog=false">Close</v-btn>
             <v-btn v-if="submit=='Confirm Appointment'" color="blue" @click="confirmOrDeny(appointment),dialog=false,dialog2= false,adminDialog=false">{{submit}}</v-btn>
             <v-btn v-else color="red" @click="confirmOrDeny(appointment),dialog=false,dialog2= false,adminDialog=false">{{submit}}</v-btn>
           </v-card-actions>
         </v-card>
   </v-dialog>


</template>


<script>

import { ref, onMounted } from 'vue'
import { db, getDb, storage } from '../firebase/config';
import { updateDoc, limit, deleteField, onSnapshot, collection, doc, getDocs, addDoc, deleteDoc, orderBy, query, where, getCountFromServer } from "firebase/firestore";
import { deleteObject, ref  as sref} from 'firebase/storage'
import emailjs from '@emailjs/browser';
import VueCal from 'vue-cal'




export default {


components: {
 VueCal
},
data: () => ({
 tab: null,
 tabCal: null,  
 requests: null,
   date: (new Date()),

   selectedEvent: [],
   calDialog: false,
   adminComments: '',
   adminComment: '',

 addReply: false,
 appointmentDelete: false,
 notifyCustomer: false
 }),
 /*
 async mounted()  {

   const bookingRef = collection(db, 'Bookings')
   const bquerySnapshots = await getDocs(query(bookingRef, orderBy("Date")))
   bquerySnapshots.forEach((doc) => {

     const newItem = {
       id: doc.id,
       title: doc.data().FirstName + ' ' + doc.data().LastName,
       start: doc.data().Date + ' ' + doc.data().TimeSelected.slice(0, 6),
       end: doc.data().Date + ' ' + doc.data().TimeSelected.slice(-6),
       artist: doc.data().Artist
       }
       
     this.eventData.push({id: doc.id,
       title: doc.data().FirstName + ' ' + doc.data().LastName,
       start: doc.data().Date + ' ' + doc.data().TimeSelected.slice(0, 4),
       end: doc.data().Date + ' ' + doc.data().TimeSelected.slice(-6,-2),
       artist: doc.data().Artist})
   })
   this.eventData.push({id:'3',
 
 end: '2023-04-23 2:00 pm',
 start: '2023-04-23 10:00',
 title: 'Dinner with John',})
   
 
},*/ 
methods: {
 refreshPage() {
   location.reload();
 },
 displayImage(){
   if(this.display){
     this.display=false
   } else{
     this.display=true
   }
 },
 

 async updateAdminComment(event) {
   
   const docRef = doc(db, "Bookings", event.id)
   
   event.adminComments = this.adminComment;
   try{
       await updateDoc(docRef, {
         AdminComments: this.adminComment,
       })

   } catch (error) {
     console.error("Error editing document: ", error);
   }
   event.addAdmin = false;
 },


 openDeleteDialogBox(event){
   this.appointment = event
   this.appointmentDelete=true
 },

 getPathStorageFromUrl(url){

     const baseUrl = "https://firebasestorage.googleapis.com/v0/b/tried-true-backend-55124.appspot.com/o/";

     let imagePath = url.replace(baseUrl,"");

     const indexOfEndPath = imagePath.indexOf("?");

     imagePath = imagePath.substring(0,indexOfEndPath);

     imagePath = imagePath.replace(/%2F/g,"/");

     imagePath = imagePath.replace(/%20/g," ");

     return imagePath;
     },

     deleteImage(collectionName,item){
       for(var i=0; i<item.Image.length; i++){
         
         let path = this.getPathStorageFromUrl(item.Image[i]);
         const imageRef = sref(storage, path)
           deleteObject(imageRef)
             .then(() => {
               console.log("tatos has been deleted")
             }).catch( (error) =>{
               console.log("ftats is still there")
             });
         }
         if(item.Proof){
         let path = this.getPathStorageFromUrl(item.Proof);
             const imageRef = sref(storage, path)
             deleteObject(imageRef)
             .then(() => {
             console.log("proof has been deleted")
             
             }).catch( (error) =>{
             console.log("proof is still there")
             });
         }
               const docRef = doc(db, collectionName, item.id)
               updateDoc(docRef, {
                 Image: deleteField(),
                 Proof: deleteField()
               });
     },
 // This sends info to form for emails to be send
 submitForm(Fname, Lname, phonenumber, address, artist, date, timeselected, bodyplacement, comments, message, deposit) {
   console.log('Deposit in form: ', deposit)
   var form = document.getElementById("myForm");
   // Set the values of the input fields
   form.elements["firstname"].value = Fname;
   form.elements["lastname"].value = Lname;
   form.elements["Email"].value = address;
   form.elements["artist"].value = artist;
   form.elements["phonenumber"].value = phonenumber;
   form.elements["date"].value = date;
   form.elements["timeselected"].value = timeselected;
   form.elements["bodyplacement"].value = bodyplacement;
   form.elements["comments"].value = comments;
   form.elements["message"].value = message;
   form.elements["deposit"].value = deposit;
   function submitForm(event){
   //Preventing page refresh
   event.preventDefault();
   }
   form.addEventListener('submit', submitForm);
 },

 //Sends confirmed appointments to bookings database
 async sendToFirebase(Fname, Lname, phonenumber, address, artist, date, timeselected, bodyplacement, comments, image, deposit, proof, adminComments){
     try {
     const docRef = await addDoc(collection(db, "Bookings"), {
         FirstName: Fname,
         LastName: Lname,
         Phonenumber:phonenumber,
         Address: address,
         Artist: artist,
         Date: date,
         TimeSelected: timeselected,
         Bodyplacement: bodyplacement,
         Comments: comments,
         Image: image,
         Deposit: deposit,
         Proof: proof,
         AdminComments: adminComments
         });
     
     } catch (e) {
     console.error("Error adding document: ", e);
     }
 },



//Sends confirmed appointments to bookings database


sendEmail() {
   // get form element
     var form = document.getElementById("my-form");

   // add event listener for form submission
   //form.addEventListener("submit", function(event) {
     // prevent default form submission behavior
     //event.preventDefault();

     // handle form submission here (e.g. send data to server using fetch API)
   //});
   console.log('called')
   try {
   emailjs.sendForm('service_h5kwe3s', 'template_l9rxbmo', this.$refs.form,
   'cz2E5MSIY_wnznOZr',{})
   console.log('Email Sent Succesufully')
   } catch(error){
     console.log({error})
   }
 },


async deleteDocumentById(collectionName, item) {
 console.log(item)
 console.log(item.id)
 try {
   if((item.Image[0]||item.Proof)&&(this.submit==="Deny Appointment"||this.appointmentDelete==true)){
     this.deleteImage(collectionName, item)
   }
   const documentRef = doc(collection(db, collectionName), item.id);
   await deleteDoc(documentRef);
 } catch (error) {
   console.error("Error deleting document: ", error);
 }
 this.refreshPage();
},




async approveItem(item) {
 this.conf = "Are you sure do you want to approve it?"
 this.reply = "Thank you for your appointment request. Your appointment has been booked."
 this.submit = "Confirm Appointment"
 this.appStatus = "confirmed"
 this.addReply = false
 this.addAdmin = false
 this.appointment = item
 

 try{
   const coll= collection(db,"Bookings")
   const q = query(coll,

           where("Artist", "==", this.appointment.Artist),
           where("Date", "==", this.appointment.Date),
           where("TimeSelected", "==", item.timeSelected),
   )
   this.existingApp = await getCountFromServer(q)
 } catch (error){
     console.log("failed finding existing appointment", error)
 }

 if (this.existingApp){
       console.log(this.existingApp.data().count)
 }
 if (this.existingApp.data().count>0){
   console.log("is this running")
   const override = confirm("There is already an appointment for this artist at this time. Would you like to add this apppointment anyway? ")
   if (override){
     this.adminDialog = true;
   } else {
     const denyAPP = confirm("Would you like to deny this appointment?")
     if(denyAPP){
       this.denyItem(item)
     }
   }
 } else {
   this.adminDialog = true;
   
 }
},

async denyItem(item) {

 this.conf = "Are you sure do you want to deny this appointment?"
 this.reply = "Thank you for your appointment request. Unfortunetely the artist is not availiable for the schedule you have requested."
 this.submit = "Deny Appointment"
 this.appStatus = "denied"
 this.addReply = false
 this.dialog = true
 this.appointment = item
 console.log('callled deny')
},

async deleteItem() {
 this.conf = "Are you sure you want to delete this appointment?"
 this.reply = "Sorry, but your appointment with Tried and True Tattoos has been cancelled. For more information, call our store.";
 this.submit = "Delete Appointment"
 this.appStatus = "deleted"
 this.addReply = false
 
 if(this.notifyCustomer)
 {
   this.dialog = true
 }
 else 
 {
   this.dialog2 = true
 }
 
 console.log('deleting appointment')
},

confirmOrDeny(item){
 
 try{
   if(this.submit==="Delete Appointment"){
     
     if(this.notifyCustomer){
       this.submitForm(item.title, item.LastName, item.Phonenumber, item.address, item.Artist, item.Date, item.timeSelected, item.Bodyplacement, item.Comments, this.reply, item.Deposit)
       console.log("Form subbmited")
       
       this.sendEmail()
     }
     this.deleteDocumentById('Bookings', item)
   }
   else 
   {
     if(this.submit==="Confirm Appointment"){
           this.sendToFirebase(item.FirstName, item.LastName, item.Phonenumber, item.Address, item.Artist, item.Date, item.timeSelected, item.Bodyplacement, item.Comments, item.Image, item.Deposit, item.Proof, this.adminComments);
       }
         this.submitForm(item.FirstName, item.LastName, item.Phonenumber, item.Address, item.Artist, item.Date, item.timeSelected, item.Bodyplacement, item.Comments, this.reply, item.Deposit)
         console.log("Form subbmited")
         
       this.sendEmail()
         this.deleteDocumentById('Appointments', item)
   }
 } catch (error) {
   console.error(error)
   return null;
 }
},


 onEventClick (event, e){
   this.selectedEvent = event
   this.calDialog = true
   this.selectedEvent.addAdmin = false
   this.adminComment = this.selectedEvent.adminComments
   e.preventDefault()
   e.stopPropagation()
 },


},

setup() {

const documents = ref([])
const timeSelected = ref('')
var dialog = ref(false)
var adminDialog = ref(false)
var dialog2 = ref(false)
var confirm = ref(false)
var display = ref(false)
var submit = ref('')
var conf = ref('')
var message = ref('')
var appointment = ref({})
var existingApp = ref('')
var appStatus = ref('')
var eventsData = ref([])
var events = ref([])
var addAdmin = ref(false)
var rickEvents = ref([])
var robEvents = ref([])
var lilyEvents = ref([])

onMounted(async() => {

   const itemsCollectionRef = collection(db, "Bookings");
   const cutoffTimestamp = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
   const oldItemsQuery = query(
     itemsCollectionRef,
     where("Date", "<", cutoffTimestamp)
   );

   onSnapshot(oldItemsQuery, (snapshot) => {

     console.log(`Found ${snapshot.size} documents older than 2h`);
     snapshot.forEach((doc) => {
       // Delete the document
       
       const baseUrl = "https://firebasestorage.googleapis.com/v0/b/tried-true-backend-55124.appspot.com/o/";
       if(doc.Image[0]){
       //delete image
         for(var i=0; i<doc.Image.length; i++){
           

           let imagePath = doc.Image[i].replace(baseUrl,"");

           const indexOfEndPath = imagePath.indexOf("?");

           imagePath = imagePath.substring(0,indexOfEndPath);

           imagePath = imagePath.replace(/%2F/g,"/");

           imagePath = imagePath.replace(/%20/g," ");

           
           const imageRef = storeref(storage, imagePath)
           
             deleteObject(imageRef)
               .then(() => {
                 console.log("tatos has been deleted")
               }).catch( (error) =>{
                 console.log("ftats is still there")
               });
           }
       }
         
         if(doc.Proof){
           

           let imagePath = doc.Proof.replace(baseUrl,"");

           const indexOfEndPath = imagePath.indexOf("?");

           imagePath = imagePath.substring(0,indexOfEndPath);

           imagePath = imagePath.replace(/%2F/g,"/");

           imagePath = imagePath.replace(/%20/g," ");
             
             const imageRef = ref(storage, imagePath)
             deleteObject(imageRef)
             .then(() => {
             console.log("proof has been deleted")
             
             }).catch( (error) =>{
             console.log("proof is still there")
             });
         }
       
         //delete document
       console.log(`Requesting #${doc.id} be deleted…`);
       deleteDoc(doc.ref) // <-- Modern Firebase SDK equivalent of ref.delete()
         .then(() => console.log(`Deleted #${doc.id} successfully.`))
         .catch((err) => console.error(`Failed to delete #${doc.id}`, err));
     });
   });

   // Get a reference to the collection you want to retrieve documents from
   const collectionRef = collection(db, 'Appointments')
   // Retrieve all documents from the collection
   const querySnapshots = await getDocs(query(collectionRef, orderBy("Date")))
   // Loop through each document and add it to the documents array
   querySnapshots.forEach((doc) => { 
     documents.value.push({ id: doc.id, ...doc.data() })
   })
   // console.log("documents", documents.value)
   
   //gets booked appointment data for all artists
   const bookingRef = collection(db, 'Bookings')
   const bquerySnapshots = await getDocs(query(bookingRef, orderBy("Date")))
   bquerySnapshots.forEach((doc) => {
     const newItem = {
       id: doc.id,
       title: doc.data().FirstName + ' ' + doc.data().LastName,
       address: doc.data().Address,
       start: doc.data().Date + ' ' + doc.data().TimeSelected.slice(0, 8),
       end: doc.data().Date + ' ' + doc.data().TimeSelected.slice(-8),
       artist: doc.data().Artist,
       content: doc.data().Comments,
       deposit: doc.data().Deposit,
       Image: doc.data().Image,
       adminComments: doc.data().AdminComments
       }
     eventsData.value.push(newItem)
     // console.log(eventsData)
   })
   
   //gets booked appointment data for only Rick Medina
   const rbookingRef = collection(db, 'Bookings')
   const rbquerySnapshots = await getDocs(query(rbookingRef, where('Artist', '==', 'Rick Medina')))
   rbquerySnapshots.forEach((doc) => {
     const newItem = {
       id: doc.id,
       title: doc.data().FirstName + ' ' + doc.data().LastName,
       address: doc.data().Address,
       start: doc.data().Date + ' ' + doc.data().TimeSelected.slice(0, 8),
       end: doc.data().Date + ' ' + doc.data().TimeSelected.slice(-8),
       artist: doc.data().Artist,
       content: doc.data().Comments,
       deposit: doc.data().Deposit,
       Image: doc.data().Image,
       adminComments: doc.data().AdminComments
       }
     rickEvents.value.push(newItem)
   })

 //gets booked appointment data for only Rob Moye
   const mbookingRef = collection(db, 'Bookings')
   const mbquerySnapshots = await getDocs(query(mbookingRef, where('Artist', '==', 'Rob Moye')))
   mbquerySnapshots.forEach((doc) => {
     const newItem = {
       id: doc.id,
       title: doc.data().FirstName + ' ' + doc.data().LastName,
       address: doc.data().Address,
       start: doc.data().Date + ' ' + doc.data().TimeSelected.slice(0, 8),
       end: doc.data().Date + ' ' + doc.data().TimeSelected.slice(-8),
       artist: doc.data().Artist,
       content: doc.data().Comments,
       deposit: doc.data().Deposit,
       Image: doc.data().Image,
       adminComments: doc.data().AdminComments
       }
     robEvents.value.push(newItem)
   })

   //gets booked appointment data for only Lily McCoy
   const lbookingRef = collection(db, 'Bookings')
   const lbquerySnapshots = await getDocs(query(lbookingRef, where('Artist', '==', 'Lily McCoy')))
   lbquerySnapshots.forEach((doc) => {
     const newItem = {
       id: doc.id,
       title: doc.data().FirstName + ' ' + doc.data().LastName,
       address: doc.data().Address,
       start: doc.data().Date + ' ' + doc.data().TimeSelected.slice(0, 8),
       end: doc.data().Date + ' ' + doc.data().TimeSelected.slice(-8),
       artist: doc.data().Artist,
       content: doc.data().Comments,
       deposit: doc.data().Deposit,
       Image: doc.data().Image,
       adminComments: doc.data().AdminComments
       }
     lilyEvents.value.push(newItem)
   })
   // console.log(lilyEvents)
})

return {
 documents,
 timeSelected,
 dialog,
 dialog2,
 adminDialog,
 conf,
 message,
 submit,
 confirm,
 appointment,
 existingApp,
 display,
 appStatus,
 eventsData,
 events,
 addAdmin,
 rickEvents,
 robEvents,
 lilyEvents
}

},
}
</script>

<style scoped>

.alignListItem {
display:flex;
justify-content: space-between;
margin-right: 10%;
margin-left: 10%;
}
.headline{

color:#f6a12e;
font: 2em Roboto;
margin: 2% ;
text-align: center;
}


.buttons{
display:flex;

}
.list-item-buttons button {
justify-content: space-between;

background-color: #f6a12e;
color: #fff;
border: none;
padding: 8px 16px;
border-radius: 4px;
font-size: 16px;
cursor: pointer;
transition: background-color 0.3s ease-in-out;
}
.list-item-buttons button:hover {
background-color: #facc8d;
}

.Form {
display: none
}

.container {
display: flex;
flex-direction: column;
align-items: center;
}
ul {
list-style-type: none;
padding: 0;
margin: 0;
}
li {
display: flex;
flex-direction: row;
align-items: center;
margin-bottom: 10px;
}
.label {
font-weight: bold;
margin-right: 20px;
}
.buttons {
display: flex;
flex-direction: row;
justify-content: center;
margin-top: 20px;
}
.approve {
background-color: green;
color: white;
border: none;
border-radius: 5px;
padding: 10px 20px;
margin-right: 10px;
cursor: pointer;
}
.calendarInfo {
margin-right: 25px;
}

.deny {
background-color: red;
color: white;
border: none;
border-radius: 5px;
padding: 10px 20px;
cursor: pointer;
}



.space {
margin-right: 10px;
}

.rick-event.vuecal__event{ background-color: "pink"; color: #f0fff1}

@media all and (max-width: 500px), print{

.mobile {
 display: contents;
}


}
</style>
