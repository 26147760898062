<template>
  <meta content="width=device-width, initial-scale=1" name="viewport" />
    <v-img
      src="store14.jpg"
      class="img"
      width="100%" 
      cover
      >
          <div class="heading">FAQ</div>
    </v-img>
<div class="topborder">
  <h1 class="h1">Before & After Care</h1>
  <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
        <v-expansion-panel
          class="insideBox" 
          title="What should I do before my tattoo appointment?" 
          text= "Be sure to get a decent night's rest the day before your appointment.
           Get a good shower in the days leading up to your appointment.
           It is also important staying hydrated (8-oz glass of water) the days leading up to your appointment.
           Be sure to eat something before you show up for your appointment.
           Moisturizing the area where you’re going to get your tattoo will go a long way in helping our artist perform their craft.
           Please dress accordingly for your session. For example, if you’re getting your leg tattooed, don’t show up in skinny jeans.
           DO NOT show up intoxicated or under the influence of ANY drugs. Especially Alcohol because it thins your blood which puts you in danger of various complications while tattooing.
           If you are going to cancel your appointment, please let us know AS SOON AS POSSIBLE. This will help us give that spot to the next patiently waiting customer.">
        </v-expansion-panel>
  </v-expansion-panels>

  <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
        <v-expansion-panel 
          class="insideBox"
          title="What should I do after my tattoo appointment?"
          text= "Once your tattoo is finished, wait a minimum of 2 hours to remove the bandage. If the bandage sticks heavily, wet the area with COOL water only to loosen it up and remove.
           Be sure to remove any surface blood and rinse off any remaining soap before proceeding.
           Once your tattoo is exposed, wash it gently using just your fingertips with mild soap and COOL water. Be as gentle as possible while washing. Do this 3 times a day for 7-14 days after your final appointment.
           Lightly pat dry and with clean hands and apply a SMALL amount of unscented coco oil to the area. Putting too much lotion will not allow the skin to breathe and heal right.
           It’s normal for your tattoo to take on a cloudy appearance followed by peeling the days following your last appointment. Do not pick or peel it. This could result in your tattoo losing color or definition.  Let it gradually work itself during the healing process for the best results.">
        </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
        <v-expansion-panel
          class="insideBox"
          title="What should I NOT do after my tattoo appointment?"
          text= "DO NOT re-bandage your tattoo, rub, scratch or pick at your new tattoo.
           DO NOT apply alcohol, Neosporin, Vaseline, or petroleum jelly to your new tattoo. Alcohol can cause infection due to trapping dirt and bacteria into the skin.
           DO NOT apply a heavy coat of lotion to your new tattoo. Only apply a little. The skin needs to breathe in order to heal properly and have a good finish.
           DO NOT swim, sauna, or steam with your tattoo for at least 2 weeks after your appointment. The salt water and chlorine act as a bleaching agent towards your new ink. Wait until you’ve fully healed up before participating in those activities.
           DO NOT expose your tattoo to direct sunlight or a tanning bed for at least 2 weeks or until you’re fully healed. This could fade color or definition of your tattoo.">
        </v-expansion-panel>
    </v-expansion-panels>
    <h1 class="h1">General Questions </h1>
    <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
          <v-expansion-panel
            class="insideBox" 
            title="How do I schedule an appointment?"
            text="You can either call the shop, email us, or request an appointment through our website. 
            Deposits are required BEFORE any consultation, appointments, or anything requested to be drawn up."
          ></v-expansion-panel>
        </v-expansion-panels>  
<v-expansion-panels class="question" variant="accordion" color="#f6a12e">
      <v-expansion-panel
            class="insideBox"
            title="How old do I have to be to get a tattoo?"
            text="You must be older than 18 to get a tattoo, or be 16-18 with parental/guardian constent. Proper documentation will be required if you are younger than 18. "
      ></v-expansion-panel>
  </v-expansion-panels>
        <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
          <v-expansion-panel
            class="insideBox"
            title="How much is a session for my tattoo?"
            text="This is determined by a number of factors. Come give us a visit to get an accurate quote on pricing!"
          ></v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
          <v-expansion-panel
            class="insideBox"
            title="Do you guys accept walk-ins?"
            text="Absolutely! It will vary on the availability of each artist but walk-ins are welcomed."
          ></v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
          <v-expansion-panel
            class="insideBox"
            title="How much is the deposit?"
            text="Deposits are $50."
          ></v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
          <v-expansion-panel
            class="insideBox"
            title="Are deposits refundable?"
            text="All deposits, regardless of if you cancel or show, are non-refundable."
          ></v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
          <v-expansion-panel
            class="insideBox"
            title="What kind of currencies do you accept as payment?"
            text="We accept Cash, Cash App, and also accept Bitcoin. We have an ATM in the shop to service credit card transactions but there will be a withdrawl fee."
          ></v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
          <v-expansion-panel
            class="insideBox"
            title="What if I’m not satisfied with the final product?"
            text="We abide by a “you get what you get” policy. "
          ></v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="question" variant="accordion" color="#f6a12e" text-colo>
          <v-expansion-panel
            class="insideBox"
            title="Do you guys do piercing?"
            text="Not at the moment. Check back regularly so you can stay in the loop if we roll anything out!"
          ></v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
          <v-expansion-panel
            class="insideBox"
            title="Do you guys hold specials around holidays or events?"
            text="We do hold regularly seasonal specials around the holidays so be sure to check our news feed on Facebook, Instagram, or here to stay up to date. 
            One of our favorite traditions we host is called “Toys for Tats” around the end of the year!"
          ></v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
          <v-expansion-panel 
            class="insideBox"
            title="What is Toys for Tats?"
            text="Starting at the beginning of November, we offer our customers the ability to bring in an unwrapped $20 toy (with the receipt) to get 40% off their selected tattoo. 
            Started back in 2009, we’re happy to host this tradition yearly with the ability to put a smile on a child’s face for the holiday season!"
          ></v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="question" variant="accordion" color="#f6a12e">
          <v-expansion-panel 
            class="insideBox"
            title="Do we speak Spanish?"
            text="Yes, we are fluent in Spanish."
          ></v-expansion-panel> 
        </v-expansion-panels>
    </div>
  </template>
  
  <style scoped>
  
    /* - phone landscape & smaller */

    @media all and (min-width: 0px) and (max-width: 480px) { 
    .question{
    height: auto;
    width: auto;
    font-size: 15px;
    margin-left: auto;
    text-align: right;
    padding: 5%;
    }

    .insideBox{
    text-align: left;
    text-overflow: inherit;
    }

    .topborder{
    border-top: 50%;
    border-color: #f6a12e;
    
    }


    .h1{
      font-size: 25px;
      text-align: right;
      
    }
    .text-h2{
    font-size: 4%;
    display: none;
    
    }


 }


 
      /* - tablets */
 @media screen and (min-width: 480px) and (max-width: 768px) { 

  .text-h2{
    margin-top: 30%;
    text-overflow: clip;
  }
 
 .h1{
      text-align: right;
      margin-right: 17%;
    }

  }


      /* - Desktop */
  .h1{
    text-align: center;
    color: #f6a12e;
    font-size: 3vw;
    
  }

  .text-h2{
    color: white;
    font: Dosis;
    margin-top:40.5%;
    margin-right: 3%;
    text-align: center;
    font-weight: 400;
}

  .question{
    width: 80%;
    padding: 1%;
    text-size-adjust: auto;
    margin: auto;
    text-align: left;
    background-color: #212122;

    font-size: 1.6vh;  

  }
  

  
  .topborder{
    border-top: 50%;
    border-color: #f6a12e;
    font-size: 21px;  
  }



  .topborder{
    
    border-top: 50%;
    border-left: 0ch;
    border-right: 0ch;
    border-bottom: 0ch;
    border-style: ridge;
    border-color: #f6a12e;
    background-color: #212122;
  }
  </style>