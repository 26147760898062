<template >
  <v-img src="store8.jpg" class="img" cover>
    <div class="heading" style="center-align">NEWS FEED</div>
  </v-img>

  <v-app class="backgroundborder">
    <v-container fluid>
      <v-row no-gutters>
    <v-btn class="mx-auto" width=200px color="secondary" v-if="isLoggedIn" @click="openCreate()">
        Create New Post
        <!--<NewsPost :create-view="true"/>-->

      </v-btn>
    </v-row>
    <v-row no-gutters>
    <v-col class="workGroup"  v-for="post in posts" :key="post.id" >
              
      <v-card :scrollabe=false class="post" color="#424242" >
        <v-card-title class="text-wrap">
          {{ post.Title }}
          
          <v-btn v-if="isLoggedIn" justify="end" size="x-small" @click="openEdit(post)">
            Edit
            <!--<NewsPost :editView="true"/>-->
          </v-btn>
        </v-card-title>
        
        <v-img :src="post.Image" @click="largePost(post)"></v-img>
        <v-card-text>{{ post.Description }}</v-card-text>
        
        <v-card-subtitle>Posted on {{ post.Date }} </v-card-subtitle>
      </v-card>
      
          </v-col>
        </v-row>
      </v-container>
    <!-- <v-row v-for="post in posts" :key="post.id" class="d-flex flex-column">
        <v-card class="post" color="#424242"
          style="margin:5px; margin-top: 1%; margin-left:5%; margin-right:5%">
          <v-card-title>
            {{ post.Title }}
            <v-btn v-if="isLoggedIn" size="x-small" @click="openEdit(post)">
              Edit
              
            </v-btn>
          </v-card-title>
          <v-card-text>{{ post.Description }}</v-card-text>
          <v-img :src="post.Image"></v-img>
        
        
          <v-card-subtitle>Posted on {{ post.Date }} </v-card-subtitle>
        
        </v-card>
      </v-row> -->


  </v-app>

  <v-dialog v-model="dialog" width="80%" persistent>
    <v-card>
      <v-card-title class="text-h5">{{ cardTitle }} </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-text-field label="Post Title" variant="outlined" v-model="title" :rules="titleRules"></v-text-field>
          </v-row>
          <v-row>
            <v-textarea label="Post Description" variant="outlined" v-model="description"
              :rules="descriptionRules"></v-textarea>
          </v-row>
          <v-row>
            <v-col cols='12'>
              <v-file-input prepend-icon="mdi-camera" label="Choose a picture" v-on:change="onImageSelected"
                accept="image/*"></v-file-input>
              <v-img :src="image"></v-img>

          </v-col>
      </v-row>
    </v-container>
  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
      <v-btn color="red" @click=" dialog= false">Close</v-btn>
    <span v-if='postEdit'>
      <v-btn v-if='image' color="red" @click="deleteImage(selectedPost)">Delete Image</v-btn>
      <v-btn color="red" @click="deletePost(selectedPost)">Delete Post</v-btn>
      <v-btn color="blue" @click="editPost(selectedPost)">Edit Post</v-btn>
    </span>
    <span v-if='postCreate'>
      <v-btn color="blue" @click="submitPost(), dialog=false">Submit</v-btn>
    </span>
  </v-card-actions>
</v-card>


</v-dialog>
<v-overlay class="expandedImage" v-model="largerPost">
<v-dialog v-model="largerPost" class="mx-auto" max-height="80%" max-width="80%">
    <v-card  color="#424242" >
        <v-card-title class="text-wrap">
          {{ title }}
          <v-btn v-if="isLoggedIn" size="x-small" @click="openEdit(selectedPost)">
            Edit
            <!--<NewsPost :editView="true"/>-->
          </v-btn>
        </v-card-title>
        
        <v-img :src="image"></v-img>
        <v-card-text>{{ description }}</v-card-text>
        
        <v-card-subtitle>Posted on {{ postDate }} </v-card-subtitle>
  </v-card>
</v-dialog>
 
</v-overlay>
</template>


<script>
import { auth, db, storage } from '../firebase/config.js'
import { onAuthStateChanged } from 'firebase/auth'
import { limit, updateDoc, onSnapshot, setDoc, collection, doc, getDocs, addDoc, deleteDoc, orderBy, query, where, getCountFromServer, deleteField } from "firebase/firestore";
import { deleteObject, ref, uploadBytes, getDownloadURL } from "firebase/storage";
export default {

components: {
},
data() {
return {
  posts: [],
  isLoggedIn: false,
  dialog: false,
  image: null,
  imageUrl: '',
  storageRef: null,
  selectedImage: null,
  postImage:null,
  postEdit: false,
  postCreate: false,
  cardTitle: '',
  title: '',
  description: '',
  selectedPost: {},
  largerPost: false,
  changingPost: false,
  titleRules: [
            value => {
                if (value) return true
                return 'Post must contain a title'
            }
        ],
        descriptionRules: [
            value => {
                if (value) return true
                return 'Post must contain a description'
            }
        ],

    }
  },
  async mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;

      }
    })
    const collectionRef = collection(db, 'NewsFeed')
    // Retrieve all documents from the collection
    const querySnapshots = await getDocs(query(collectionRef, orderBy("Timestamp", "desc")))
    // Loop through each document and add it to the documents array
    querySnapshots.forEach((doc) => {
      this.posts.push({ id: doc.id, ...doc.data() })
      
    })
  },

  methods: {



    refreshPage() {
      location.reload();
    },

    //reformats the date
    formatDate(date) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options)
    },

    //listener on the image input, prepares image for upload
    onImageSelected(event) {

      this.selectedImage = event.target.files[0];
      const submitDate = Date.now()
      this.newImageName = submitDate.toString() + this.selectedImage.name
      this.storageRef = ref(storage, 'folder/' + this.newImageName);
      console.log(typeof this.selectedImage.name)
      console.log(this.selectedImage.name)
      console.log(this.newImageName)
      console.log(this.selectedImage, this.storageRef)
    },

    //displays larger image on desktop view
    async largePost(post) {
      this.postDate=post.Date
      this.title = post.Title
      this.description = post.Description
      this.image = post.Image
      var w = window.screen.width;
      this.selectedPost = post
      console.log(this.selectedPost)
      if (w<630){
        this.largerPost = false;
        console.log(w);
      }
      else {
        this.largerPost=true;
        console.log(w);
      }
      console.log(this.largerPost);
    },

    //uploads image to firebase storage
    async uploadImage() {

      await uploadBytes(this.storageRef, this.selectedImage)
        .then((snapshot) => {
          console.log("File uploaded successfully!");
        }).catch((error) => {
          console.error("Error uploading file:", error);
        });

      await getDownloadURL(this.storageRef)
        .then((url) => {
          this.imageUrl = url;
          console.log('Image uploaded successfully:', this.imageUrl);
        })
        .catch((error) => {
          console.error("Error getting download url: ", error)
        });


    },

    //find the image file path from the url stored in the database
    getPathStorageFromUrl(url) {

      const baseUrl = "https://firebasestorage.googleapis.com/v0/b/tried-true-backend-55124.appspot.com/o/";

      let imagePath = url.replace(baseUrl, "");

      const indexOfEndPath = imagePath.indexOf("?");

      imagePath = imagePath.substring(0, indexOfEndPath);

      imagePath = imagePath.replace(/%2F/g, "/");

      imagePath = imagePath.replace(/%20/g, " ");

      return imagePath;
    },

    //deltes an image from firebase storage and the post
    async deleteImage(post) {
      console.log("try to delete")
      console.log(post.Image)
      let path = this.getPathStorageFromUrl(post.Image);
       console.log(path)
       const imageRef = ref(storage, path)
       console.log(imageRef)
      //  imageRef.delete().then(()=>{
      //   console.log("file has been deleted");
      //     const docRef = doc(db, "NewsFeed", doc.id);
      //     console.log(docRef);
      //     updateDoc(docRef, {
      //       Image: deleteField()
      //     });
      // }).catch(function(error){
      //   console.log("file is still there");
      // })
      // let path = this.getPathStorageFromUrl(doc.Image);
      // console.log(path)
      // const imageRef = ref(storage, path)
      // console.log(imageRef)
      deleteObject(imageRef)
        .then(() => {
          console.log("file has been deleted");
          
        }).catch((error) => {
          console.log("file is still there")
        });
        const docRef = doc(db, "NewsFeed", post.id);
          console.log(docRef);
          await updateDoc(docRef, {
            Image: deleteField()
          });
          console.log(this.changingPost)
        if(!this.changingPost){
          this.refreshPage();
        }
    },

    //sends information to firebase
    async sendToFirebase(title, timestamp, description, image) {
      try {
        var date = timestamp.toLocaleDateString()
        const docRef = await addDoc(collection(db, "NewsFeed"), {
          Title: title,
          Date: date,
          Description: description,
          Image: image,
          Timestamp: timestamp
        });
        console.log("Document written with ID: ", docRef.id);
        // reset();
        console.log("Working");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },

    //conditional rendering if making a new post
    async openCreate() {
      this.dialog = true;
      this.postCreate = true;
      this.postEdit = false;
      this.cardTitle = "Create a New Post: "
      this.title = ''
      this.description = ''
      this.image = ''
    },

    //conditional rendering if editing existing post
    async openEdit(post) {
      this.dialog = true;
      this.postEdit = true;
      this.postCreate = false;
      this.cardTitle = "Edit Post"
      this.title = post.Title
      this.description = post.Description
      this.image = post.Image
      this.selectedPost = post
    },

    //prepares post to be send to firebase
    async submitPost() {
      console.log(this.postImage)
      console.log('tried to submit post')
      console.log(this.selectedImage)
      if (this.selectedImage) {
        console.log("checking url before firestore", this.imageUrl)
        await this.uploadImage()
        console.log("checking url after firestore", this.imageUrl)
      }
      const d = new Date()
      const date = d.toLocaleDateString()
      await this.sendToFirebase(this.title, d, this.description, this.imageUrl)
      this.refreshPage()
    },

    //edits an existing post an updates firebase
    async editPost(post) {
      this.changingPost=true;
      console.log("try to edit post")
      console.log(post)
      const docRef = doc(db, "NewsFeed", post.id)
      try {
        if (this.selectedImage) {
          console.log("change image")
          console.log("checking url before firestore", this.imageUrl)
          if (post.Image) {
            await this.deleteImage(post)
          }

          await this.uploadImage()
          console.log("checking url after firestore", this.imageUrl)
          await updateDoc(docRef, {
            Title: this.title,
            Description: this.description,
            Image: this.imageUrl,
          })
        } else {
          console.log("don't change image")
          await updateDoc(docRef, {
            Title: this.title,
            Description: this.description
          })
        }

      } catch (error) {
        console.error("Error editing document: ", error);
      }
      this.changingPost=false;
      this.refreshPage()
      this.dialog = false
    },

    //deletes post from firebase
    async deletePost(post) {
      console.log(post)
      this.changingPost = true;
      try {
        const documentRef = doc(db, 'NewsFeed', post.id);
        if(post.Image){

        await this.deleteImage(post);
      }
        await deleteDoc(documentRef);
        console.log("Document deleted successfully!");
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
      this.changingPost = false;
      this.refreshPage()
      this.dialog = false
    },
  },

}

</script>

<style>

@media only screen and (max-width: 480px) {


.expandedImage {
  display:none;
}
}
.v-card {
  overflow-y: hidden;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.heading{
    font-size: 4rem !important;
    font-weight: 400;
    line-height: 3.75rem;
    letter-spacing: -0.0083333333em !important;
    text-transform: none !important;

    color: white;
    margin-top: 37%;
    margin-bottom: 1%;
    text-align: center;
}

.text-h2 {
  color: white;
  font: Dosis;
  margin-top: 40.5%;
  margin-right: 3%;
  font-weight: 400;
  text-align: center;
}

.backgroundborder {
  border-top: 50%;
  border-left: 0ch;
  border-right: 0ch;
  border-bottom: 0ch;
  border-style: ridge;
  border-color: #f6a12e;
  background-color: #212122;
}

.post {
  color:"#424242";
  margin:5px; 
  margin-top: 1%; 
  margin-left:5%; 
  margin-right:5%;
  margin-bottom: 5%;
  padding-top: 1%;
	padding-bottom: 5%;      
}


.largePost {
  min-width: '80%'
}
.buttonRow {
	padding-top: 5%;
	padding-bottom: 5%;
	margin-top: 5%;
	margin-bottom: 5%;
	height: 10%;
}

.expandedImage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.workGroup {
  min-width: 300px;
}
</style>