<template>
    <v-app>
    <v-container>
        <h1 class = 'text-center'> Admin Only Login</h1>
        <v-form class="login">
                      <v-text-field
                          class="fillForm"
                          name="Email"
                          label="Email"
                          id="address"
                          v-model="address"
                          required>
                      </v-text-field>
                      <v-text-field
                          class="fillForm"
                          name="Password"
                          label="Password"
                          id="password"
                          type="password"
                          v-model="password"
                          required
                          @keydown.enter="login">
                      </v-text-field>
                      <v-btn
                          color="secondary"
                          class="submitBtn"
                          size="large"
                          @click="login"
                          >Login</v-btn>
                          
              </v-form>
              <!-- These are the errors that will display on loggin -->
              <p v-if="errMsg">{{ errMsg }}</p>
              <p><router-link to='/passwordReset'> {{ fixMsg }}</router-link></p>

    </v-container>
  </v-app>
</template>
  
  
<script>
  
import {signInWithEmailAndPassword,onAuthStateChanged} from "firebase/auth"
import {auth} from '@/firebase/config.js';
  
  // Components
  export default {
      components: {  },
      name: 'Login',
  
      data: () => ({
        address: '',
        password: '',
        errMsg: '',
        fixMsg: '',
      }),
  
    methods: {
        login() {
            signInWithEmailAndPassword(auth, this.address, this.password)
            .then((data) => {
                
                this.$router.push('/');
            })
            .catch((error) => {
                switch(error.code){
                    
                    case "auth/wrong-password":
                        this.errMsg = "Wrong password";
                        this.fixMsg = "Click here to reset your password";
                        break;
                    default:
                        this.errMsg = "Wrong Email or Password";
                        this.fixMsg = "Click here to reset your password";
                        break;
                                
                }
            });

        },
    
    mounted(){
            onAuthStateChanged(auth, (user) => {
                if(user){
                    this.username= user.email.slice(0,user.email.indexOf('@'));
                    this.address = user.email;
                    this.diplay= user.displayName;
                } else {
                    this.display="";
                    this.username="";
                }
            })
        }
    }
  }
  
  </script>
  
  <style scoped>
  .login{
      text-align: center;
      margin-right: 20%;
      margin-left: 20%;
  }
  
  .text-h2{
      color:#f6a12e;
      font: 1em Dosis;
      margin-top:30%;
      margin-left:24%;
      margin-bottom:1%;
  }
  .login{
      margin-top: 2%;
  }
  .submitBtn{
      margin-left:1%;
  }
  </style>