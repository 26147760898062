<template>
    <div id="na">
        <v-app-bar  color="primary" :elevation="2">
            <v-app-bar-nav-icon @click="drawer = true" class="d-flex d-lg-none"></v-app-bar-nav-icon>
            <h3 class="d-lg-none" color="#f6a12e">Tried and True Tattoos</h3> 
            <v-img max-width="250" src="Logo.png" class="d-none d-lg-block"></v-img>
            <div class="d-none d-lg-block" >
            <v-btn v-for="link in links" :key="link.text" router :to="link.route" color="secondary">
                {{ link.text }}
            </v-btn>
            </div>
            <v-menu class="d-none d-lg-block">
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" color="secondary" class="d-none d-lg-block">Artists</v-btn>
                </template>
                <v-list>
                    <v-list-item
                        class="artistperson"
                        v-for="artist in artists"
                        :key="artist.text"
                        router
                        :to="artist.route"
                        color="secondary"
                        >
                        <v-list-item-title>{{ artist.text }}</v-list-item-title>
                    </v-list-item>
                 </v-list>

            </v-menu>
            <div class="d-none d-lg-block">
            <v-btn v-for="links in linksCont" :key="links.text" router :to="links.route" color="secondary">
                {{ links.text }}
            </v-btn>
            </div>
            <v-spacer></v-spacer>
            <div class="d-none d-lg-block" >
            <v-btn v-if="isLoggedIn" router to="/confirmation" color="info">Appointment Requests</v-btn>
            </div>
            
            <span v-if="isLoggedIn===false" class="d-none d-lg-block" >
                <v-btn v-for="link in signedOutLinks" :key="link.text" router :to="link.route" color="secondary">
                {{ link.text }}
            </v-btn>
            </span>

            <v-btn class="d-none d-lg-block" v-if="isLoggedIn" @click="handleSignOut" >Logout</v-btn>
            
            
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            sticky
            temporary
            >
            <v-list nav dense
            >
                <v-list-item-group>
                    <v-list-item v-for="link in links" :key="link.text" router :to="link.route" color="secondary">
                        <v-list-item-title> {{link.text}} </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
                <v-list-group value="Artists">
                    <template v-slot:activator="{ props }">
                        <v-list-item
                            v-bind="props"
                            title="Artists"
                            ></v-list-item>
                    </template>
                    <v-list-item v-for="artist in artists"
                        :key="artist.text"
                        router
                        :to="artist.route"
                        :title="artist.text"></v-list-item>
                </v-list-group>
                <v-list-item-group>
                    <v-list-item v-for="link in linksCont" :key="link.text" router :to="link.route" color="secondary">
                        <v-list-item-title> {{link.text}} </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
                <v-list-item-group>
                    <v-list-item v-if="isLoggedIn" router to="/confirmation" color="info">Appointment Requests</v-list-item>
                <span v-if="isLoggedIn===false">
                <v-btn v-for="link in signedOutLinks" :key="link.text" router :to="link.route" color="secondary">
                {{ link.text }}
            </v-btn>
            </span>

            <v-btn v-if="isLoggedIn" @click="handleSignOut" color="secondary">Logout</v-btn>

                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
    <router-view />
</template>

<script>
import {onAuthStateChanged, signOut} from "firebase/auth";
import {auth} from "@/firebase/config.js"
export default {
    data() {
        return {
            drawer: false,
            links: [
                { icon: 'home', text: 'Home', route: '/' },
                { icon: 'view-list', text: 'Appointment', route: '/appointment' }
            ],
            linksCont: [
                { icon: 'newsfeed', text: 'News Feed', route: '/news'},
                { icon: 'faq', text: 'FAQ', route: '/faq'},
                { icon: 'robs knives', text:"Rob's Knives", route: '/robsknives'},
                { icon: 'contact', text: 'Contact Us', route: '/contact'},
            ],
            artists: [
                {text: 'Rick Medina', route: '/Rick-Medina'},
                {text: 'Rob Moye', route: '/Rob-Moye'},
                {text: 'Lily McCoy', route: '/Lily-McCoy'}
            ],
            // commented out to run on the server
            // { icon: 'contact', text: 'Contact Us', route: '/contact'},
            // ],
            signedOutLinks: [
                { icon: 'login', text: 'Admin', route: '/login'},
            ],
            
            isLoggedIn: false,
        }
    },
    methods: {
    handleSignOut() {
      signOut(auth);
      this.$router.push('/');
    },
  },
  mounted(){
      onAuthStateChanged(auth, (user) => {
        if(user){
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;

        }
      })
  },
}
</script>

<style scoped>
.artistperson{
    color: #5cffe5
}
</style>
