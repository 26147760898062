<template>
    <v-app>
      <v-main>
        <FAQ/>
      </v-main>
    </v-app>
</template>
  
  <script>
  // Components
  import FAQ from '@/components/FAQ'
  
  export default {
    name: "FAQView",
  
    components: {
        FAQ
    },
  };
  </script>