<template>
  <v-row>
    <v-card center class="v-card" >
      <v-row v-if="desktopView">
        <v-col cols="6">
          <div class="InfoHeading" > Meet Lily McCoy </div> 
          <div class="LilyInfo">
            <h4>Under the apprenticeship of Rick, Lily has recently started learning the ropes of tattooing here at Tried-And-True. She’s always had a fascination with creating and drawing from a young age. 
              She was originally going to college for Graphic Design until one of her friends turned her on to the idea of tattooing for a living. 
              After a little encouragement, she pulled the trigger and is now on her way to becoming the next top-notch artist at Tried-and-True Tattoos. Come by and say what's up to Lily by booking an appointment with her today!</h4>
          </div></v-col>
          <v-col class="artist" width=80% cols=4 align-self="center">
          <v-img src="Lily1.jpg" class="artists" height="auto" width="auto" cover></v-img>
            </v-col>
      </v-row>
      <v-row v-if="!desktopView" justify="center" >
        <v-col cols="10">
          <div class="MobileInfoHeading" > Meet Lily McCoy </div> 
          
          <v-img src="Lily1.jpg" class="mx-auto" height="auto" width="auto"></v-img>
            
          <div class="MobileLilyInfo">
            <h4>Under the apprenticeship of Rick, Lily has recently started learning the ropes of tattooing here at Tried-And-True. She’s always had a fascination with creating and drawing from a young age. 
              She was originally going to college for Graphic Design until one of her friends turned her on to the idea of tattooing for a living. 
              After a little encouragement, she pulled the trigger and is now on her way to becoming the next top-notch artist at Tried-and-True Tattoos. Come by and say what's up to Lily by booking an appointment with her today!</h4>
          </div></v-col>
          
      </v-row>
     </v-card>
  </v-row>
<v-card class="blackbackground">
<v-container>
    <v-row>
        <v-col cols="12">
            <div class="text-h4">View Her Portfolio</div> 
        </v-col>
    </v-row>
    <v-row>
      <v-carousel show-arrows="hover" hide-delimiter-background>
        <v-carousel-item v-for="(work, i) in works" :key="i" :src="work.src" @click="expandImage(work.src)"></v-carousel-item>
      </v-carousel>
    </v-row>
</v-container>
</v-card>
<v-overlay class="expandedImage" v-model="imageExpand">
 
  
 <img :src="expandedImage">

</v-overlay>
</template>

<script>
export default {

data: () => ({
works: [
  {src: "LM picture 11.png"},
  {src: "LM picture 12.png"},
  {src: "LM picture 14.png"},
  {src: "LM picture 16.png"},  
  {src: "LM picture 17.png"},
  {src: "LM picture 19.png"},
  {src: "LM picture 20.png"},
  {src: "LM picture 15.png"},
  {src: "LM picture 13.png"},
  {src: "LM Picture 10.png"},
  {src: "LM picture 22.png"},
  {src: "LM picture 21.png"},
],
imageExpand:false,
expandedImage: '',
desktopView: false,
}),
mounted() {
      if(this.isMobileView()){
        this.desktopView=false;

      }
      else {
        this.desktopView=true;
      }
      console.log(this.desktopView)
  },
methods: {
  isMobileView(){
    var w = window.screen.width;
    console.log(w);
    if (w<650){
      
      return true;
    }
    else {
      
      return false;
    }
  },
expandImage(image){
  if(!this.isMobileView()){
  this.imageExpand=true
  this.expandedImage = image
  }
}
}
};
</script>

<style scoped>

/* MOBILE VIEW*/

@media all and (max-width: 650px) {

.InfoHeading{
font-size: 3px;
text-align: center;
margin: 0%;
}

h4 {
font-size: 15px;
display: flex;
}


.artist {
margin: auto;
}
.InfoHeading{
display: block;
}
.LilyInfo{
margin: auto;
text-align: center;

}

}

.MobileInfoHeading{
margin:auto;
font-size: 45px;
text-align: center;
color: #f6a12e;
padding: auto;
}

.MobileLilyInfo {
margin: 5% auto;
text-align: center;
}

/* LAPTOP VIEW */ 

@media all and (min-width: 650px) and (max-width: 1800px) {

h4 {
font-size: 1.8vw;
}

.InfoHeading{
text-align: center;
}
.LilyInfo{
text-align: center;
}
.artist {
margin-left: 2%;
}
}


/* DESKTOP VIEW */ 

.InfoHeading {
margin-top: 5%;
margin-left: 16%;
margin-bottom: 1%;
font-size: 45px;
text-align: center;
color: #f6a12e;
}

.expandedImage {
display: flex;
align-items: center;
justify-content: center;
}
.LilyInfo {
margin-left: 16%;
margin-bottom: 5%;
font-size: 35px;

}
.text-h4{
margin-top:2%;
text-align: center;
color:#f6a12e;
}

.artist{
  margin-left:6%;
  margin-top:3%;
  margin-bottom:3%;
}

.blackbackground{
background-color: #000000;
}
</style>