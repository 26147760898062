<template>
    <v-app>
    <v-container>
        <h1 class='text-center'>Reset your Password</h1>
        <v-form class="passReset">
            <v-text-field
                          class="fillForm"
                          name="Email"
                          label="Email"
                          id="address"
                          v-model="address"
                          required>
                      </v-text-field>
                      <v-btn
                          color="secondary"
                          class="submitBtn"
                          size="large"
                          @click="resetPassword"
                          >Reset Password</v-btn>
                          
        </v-form>
    </v-container>
    <p v-if="errMsg" class="text-center">{{ errMsg }}</p>
    </v-app>
</template>

<script>
import {sendPasswordResetEmail,onAuthStateChanged} from "firebase/auth"
    import {auth} from '@/firebase/config.js';
  
  // Components
  export default {
      components: {  },
      name: 'Login',
  
      data: () => ({
        address: '',
        errMsg: '',
        fixMsg: '',
        linkMsg:'',
      }),
  
    methods: {
        resetPassword() {
        sendPasswordResetEmail(auth,this.address)
            .then(() => {
                alert("Email sent successfully!");
                this.$router.push("/");
            })
            .catch((error) => {
                switch(error.code){
                case "auth/invalid-email":
                    this.errMsg = "Invalid email";
                    break;
                case "auth/user-not-found":
                    this.errMsg = "No account with that email was found";
                    break;
                       
        }
        });

        },
    
    mounted(){
            onAuthStateChanged(auth, (user) => {
                if(user){
                    this.username= user.email.slice(0,user.email.indexOf('@'));
                    this.address = user.email;
                    this.diplay= user.displayName;
                } else {
                    this.display="";
                    this.username="";
                }
            })
        }
    }
  }
  
  </script>
  
  <style scoped>
  .passReset{
      text-align: center;
  }
  
  .text-h2{
      color:#f6a12e;
      font: 1em Dosis;
      margin-top:30%;
      margin-left:24%;
      margin-bottom:1%;
  }
  .passReset{
      margin-top: 2%;
  }
  .submitBtn{
      margin-left:1%;
  }
  
</style>
