<template>
    <v-app>
      <v-main>
        <PasswordReset />
      </v-main>
    </v-app>
  </template>
  
<script>
import PasswordReset from '@/components/PasswordReset'

export default ({
name: "PasswordResetView",
components: {
    PasswordReset
}
})
</script> 