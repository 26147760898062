import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue"
import AppointmentView from "../views/AppointmentView.vue";
import ContactView from "../views/ContactView.vue";

import RickView from "../components/RickView.vue";
import RobView from "../components/RobView.vue";
import LilyView from "../components/LilyView.vue";
import NewsView from "../views/NewsView.vue"
import RobsKnivesView from "../views/RobsKnivesView.vue"
import FAQView from "../views/FAQView.vue"

import LoginView from "../views/LoginView.vue";

import PasswordResetView from "../views/PasswordResetView.vue"
import {auth} from "../firebase/config.js"

import ConfirmationView from "../views/ConfirmationView.vue"



// const routes = [
//   {c
//     path: "/",
//     name: "home",
//     component: HomeView,
//   },
//   {
//     path: "/about",
//     name: "about",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//       import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
//   },
//   {
//     path: "/appointment",
//     name: "appointment",
//     component: AppointmentView,
//   },
// ];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView
    },
    {
      path: "/about",
      name: "about",
      component: AboutView
    },
    {
      path: "/appointment",
      name: "appointment",
      component: AppointmentView
    },
    {
      path: "/news",
      name: "news",
      component: NewsView
    },
    {
      path: "/FAQ",
      name: "FAQ",
      component: FAQView
    },
    {
      path: "/contact",
      name: "contact us",
      component: ContactView
    },
    {

      path: "/Rick-Medina",
      name: "Rick Medina",
      component: RickView
    },
    {
      path: "/Rob-Moye",
      name: "Rob Moye",
      component: RobView
    },
    {
      path: "/Lily-McCoy",
      name: "Lily McCoy",
      component: LilyView
    },
    //Added Semicolon
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: {
        guestRequired: true,
    },
    },
    
    {
      path: "/passwordReset",
      name: "passwordReset",
      component: PasswordResetView,
    },
    {
      path: "/confirmation",
      name: "confirmation",
      component: ConfirmationView,
      meta: {
        authRequired: true,
      }
    },
    {
      path: "/robsknives",
      name: "robsknives",
      component: RobsKnivesView
    },

  ],
});

router.beforeEach((to, from, next) => {
  auth.onIdTokenChanged((user) => {
      
      if(to.matched.some((record)=> record.meta.authRequired) && user){
          next();
      } else if (to.matched.some((record)=> record.meta.guestRequired) && user){
          next("/")
      } else if (to.matched.some((record)=> record.meta.authRequired)){
          next("/");
      } else {
          next();
      }
 
  })
});

export default router;
