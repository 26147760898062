<template>
    <v-snackbar v-model="snackbar" color="secondary" location="top">
        <v-icon icon="mdi-email-send-outline"></v-icon>
        Message Sent
        <template v-slot:actions>
            <v-btn variant="text" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
    <v-img src="Contact.jpg" class="img" cover>
        <div class="heading">CONTACT US</div>
        
    </v-img>
    <div class="backgroundborder">
        <v-container>
            <v-row>
                <v-col class="infoSec">

                    <div class="text-h4">ADDRESS</div>
                    <h3 class="text"> 811 E Main St Suite B, Russellville, AR 72801</h3>

                    <div class="text-h4">PHONE</div>
                    <h3 class="text">(479) 219-5029</h3>

                    <div class="text-h4">EMAIL</div>
                    <h3 class="text">rmstriedandtrue@gmail.com</h3>

                    <div class="text-h4">STORE HOURS</div>
                    <v-table class="table" bord>
                        <tbody>
                            <tr v-for="item in days" :key="item.day">
                                <td>{{ item.day }} </td>
                                <td>{{ item.time }} </td>
                            </tr>
                        </tbody>
                    </v-table>
                    <h3 class="text">Store hours may vary on a day-to-day basis.</h3>
                
                </v-col>
                
                <v-col class="questionSec">
                    
                    <div class="text-h4">HAVE ANY QUESTIONS?</div>
                    <h3>Let us know! </h3>
                    <v-form v-model="valid">
                    <form class="form" ref="form">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="firstname" :rules="nameRules" label="First name" name="firstname"
                                        required></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="lastname" :rules="nameRules" label="Last name" name="lastname"
                                        required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="email" :rules="emailRules" label="E-mail" name="email"
                                        required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="phonenumber" :rules="phoneRules" label="Phone Number"
                                        name="phonenumber" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="subject" :rules="subjectRules" label="Subject" name="subject"
                                        required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea v-model="message" :rules="messageRules" label="Message" name="message"
                                        required>
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn color="secondary" class="submitBtn" size="large" :disabled="!valid"
                                    @click="submitHandler">Enter</v-btn>
                            </v-row>
                        </v-container>
                    </form>
                </v-form>
            
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default ({

    data() {
        return {
            days: [
                {
                    day: "Monday",
                    time: "1pm - 9pm"
                },
                {
                    day: "Tuesday",
                    time: "1pm - 9pm"
                },
                {
                    day: "Wednesday",
                    time: "1pm - 9pm"
                },
                {
                    day: "Thursday",
                    time: "1pm - 9pm"
                },
                {
                    day: "Friday",
                    time: "1pm - 11pm"
                },
                {
                    day: "Saturday",
                    time: "1pm - 11pm"
                },
                {
                    day: "Sunday",
                    time: "1pm - 9pm"
                }
            ],
            firstname: '',
            lastname: '',
            email: '',
            phonenumber: '',
            message: '',
            subject: '',
            valid: false,
            snackbar: false,
            nameRules: [
                value => {
                    if (value) return true
                    return 'Name Required'
                }
            ],
            phoneRules: [
                value => {
                    if (!value) return 'Phone number required'
                    if (value?.length > 9 && /[0-9-]+/.test(value)) return true
                    return 'Invalid phone number'
                }
            ],
            emailRules: [
                value => {
                    if (!value) return 'Email required'
                    if (/^[a-z.-.0-9]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true
                    return 'Must be a valid e-mail.'
                }
            ],
            selectRules: [
                value => {
                    if (value) return true
                    return 'Selection required'
                }
            ],
            subjectRules: [
                value => {
                    if (value) return true
                    return 'Subject Required'
                }
            ],
            messageRules: [
                value => {
                    if (value) return true
                    return 'Message Required'
                }
            ],
        }
    },

    methods: {

        reset() {
      location.reload();
        },
        async submitHandler() {
            console.log('called')
            try {
                await emailjs.sendForm('service_h5kwe3s', 'template_jl9zesj', this.$refs.form,
                    'cz2E5MSIY_wnznOZr', {}
                )
                console.log('Email Sent Succesufully')
                this.snackbar = true
                this.reset()
            }
            catch (error) {
                console.log({ error })
            }
        },
    }

})
</script>

<style scoped>

@media all and (max-width: 900px) {

.toptext {
  font-size: 3px;
}

.text-h4  {
    text-align: center;
}

h3 {

  font-size: 15px;
  text-align: center;
}

.text-h3 {
  font-size: 3px;

}

.infoSec {
    padding-left: 0%;
    padding-right: 0%;
    margin: 0 5%;
  min-width: 300px;
  min-height: 300px;
}
.questionSec {
    margin-right: 5%;
  padding-bottom: 10%;
 width:auto;
}

}
@media all and (min-width: 1400px) {

}

.heading1 {
    text-align: bottom;
}
.text-h2 {
    /* color: #f6a12e;
    font: 40 Dosis;
    margin-top:40.5%;
    margin-right: 3%;
    text-align: center;
    font-weight: 400; */
    text-align: bottom;
}

.text-h4 {
    color: #f6a12e;
    margin-top: 2%;
}

.location {
    margin-top: 3%;
}

.text {
    margin-top: 1%;
}



.whole {
    min-width: 800px;
}
.infoSec {
    padding-left: 5%;
    padding-right: 5%;
  min-width: 300px;
  min-height: 300px;
}
.questionSec {
    margin-right: 5%;
  padding-bottom: 10%;
  min-width: 300px;
}

.form {
    margin-top: 1%;
}

.submitBtn {
    margin-left: 2%;
}

.backgroundborder {
    border-top: 50%;
    border-left: 0ch;
    border-right: 0ch;
    border-bottom: 0ch;
    border-style: ridge;
    border-color: #f6a12e;
    background-color: #212122;
}
</style>
