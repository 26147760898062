<template>
    <v-app>
      <v-main>
        <RobsKnives/>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import RobsKnives from '@/components/RobsKnives'
  
  export default ({
    name: "RobsKnivesView",
    components: {
      RobsKnives
    }
  })
  </script>