<template>
  <v-app>
    <v-main>
      <Appointments />
    </v-main>
  </v-app>
</template>

<script>
import Appointments from '@/components/Appointments'

export default ({
  name: "AppointmentView",
  components: {
    Appointments
  }
})
</script>
