import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBCxz7Sz8aL0pKp7Jz7MCLNUDPsCn43aaQ",
  authDomain: "tried-true-backend-55124.firebaseapp.com",
  projectId: "tried-true-backend-55124",
  storageBucket: "tried-true-backend-55124.appspot.com",
  messagingSenderId: "824949107212",
  appId: "1:824949107212:web:2998a6d79b1c67d9305c29",
  measurementId: "G-8ZZ17P3Z1C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Authentication and get a reference to the service
const auth = getAuth(app);
// Initialize Cloud Storage and get a reference to the service
const db = getFirestore(app);
//Initialize FireBase Storage and get a reference to the service
const storage = getStorage(app);



export { db,auth,storage, ref}
