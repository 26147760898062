<template>

  <meta name="viewport" content="width=device-width,initial-scale=1.0">
  <v-img src="Homepage.jpg" width="100%" class="img">
    <v-img src="Logo.png" class="logo" width="60%"></v-img>
  </v-img>
  <div class="backgroundborder">
    <v-container fluid>
      <v-col cols="12" style="overflow-y: hidden;">
        <v-row>
          <v-card color="#212122" center max-height="5000">
            <v-row>

              <v-row>
                <v-col cols="12" md="7">
                  <div class="headline">TATTOO SHOP IN RUSSELLVILLE, AR </div>
                  <div class="intro">
                    <h3>Tried-And-True Tattoos tells you exactly what to expect up front in its name. Honest and
                      hard-working artists that care about the way you want to express yourself. Artist owned and
                      operated, Tried-And-True has been serving the Russellville community since June of 2014. Each artist
                      specializes in a variety of styles from American Traditional, Neo Traditional, Old & New School,
                      Black & Grey, Realism, to Reworks and Customs. Whatever you have in mind, the artists at
                      Tried-And-True are up for the challenge of providing you with the highest quality tattoo possible.
                      Come by or book an appointment today to meet our amazing crew!</h3>

                      <br/>
                  </div>
                </v-col>
                <v-col cols="4" class="introPic" m="4">
                  <v-row align="center" justify="center">
                    <v-img class="introPic" src="outside.jpg"></v-img>
                  </v-row>
                </v-col>
              </v-row>

            </v-row>
          </v-card>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-card color="primary" center class="v-card">
            <v-row>
              <v-col class="text-center">
                <div class="headline">OUR TATTOO ARTISTS </div>
                <div class="artistsInfo">
                  <h4>Our artists specialize in many different styles. These styles range from American Traditional to Neo
                    Traditional, Old School, New School, Black and Grey, Realism and much more. There is no challenge that
                    our artists cannot handle. We love our job, and we aim to make our clients satisfied with our work.
                  </h4>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="artistGroup" cols='12' v-for="(artist, i) in artists" :key="i" xs="12" md="3">
                <div align="center" justify="center">
                  <v-img class="artists" max-height="300" max-width="300" cover :src="artist.img"></v-img>
                </div>
                <v-card-title class="text-center">{{ artist.title }}</v-card-title>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-col>



      <v-row>
        <v-col class="text-center">
          <div class="headline">RECENT WORK</div>
        </v-col>
      </v-row>
      <v-row>
        <v-carousel show-arrows="hover" hide-delimiter-background>
            <v-carousel-item v-for="(work, i) in works" :key="i" :src="work.src" @click="expandImage(work.src)"></v-carousel-item>
          </v-carousel>
      </v-row>
<div class="text-center">
  <br/>
      <h3 class="text-h4">All transactions are non-refundable and final.</h3>
      <h3 class="text-h4"> We have a right to refuse service to anyone.</h3>
</div>
    </v-container>
    
  
  
  
</div>

<v-overlay class="expandedImage" v-model="imageExpand">
   
    
   <img :src="expandedImage">
 
</v-overlay>

</template>

<script>
import { onMounted } from 'vue';
import { useDisplay } from 'vuetify/lib/framework.mjs';

export default {
  name: "HelloWorld",

  data: () => ({
    artists: [
      { img: "rick medina.png", title: "Rick Medina" },
      { img: "Rob1.png", title: "Rob Moye" },
      { img: "Lily1.jpg", title: "Lily McCoy" }

    ],
    works: [
      { src: "RM picture 10.png" },
      { src: "RM picture 4.png" },
      { src: "RB Picture 1.png" },
      { src: "RB Picture 4.png" },
      { src: "LM picture 14.png" },
      { src: "LM picture 13.png" },
      { src: "RB picture 19.png" },
      { src: "RM picture 2.png" },
      { src: "RM picture 6.png" },
      { src: "LM picture 17.png" },
      { src: "RB Picture 9.png" },
      { src: "RB Picture 7.png" },
    ], 
imageExpand:false,
expandedImage: '',
  }),
  methods:{
    expandImage(image){
    this.imageExpand=true
    this.expandedImage = image
    console.log(this.imageExpand, this.expandedImage)
    }
    },

  setup() {
    const { mobile } = useDisplay()

    onMounted(() => {
      console.log(mobile.xs)
    })
  }

};
</script>
<style scoped>

html {
    overflow-x: hidden;
    width: 100%;
}

body {
    overflow-x: hidden;
    width: 100%
}
/* - phone landscape & smaller */
@media all and (max-width: 950px) {

  .toptext {
    font-size: 3px;
  }

  .introPic {
    height: auto;
    width: auto;
  }


  h3 {

    font-size: 15px;
    text-align: center;
  }

  .headline {
    text-align: center;

  }



}

/* - Desktop */


.expandedImage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headline {
  color: #f6a12e;
  margin: auto 2%;
  font-size: 2em;
}

.img {
  margin: 0;
  padding: 0;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.v-card {
  width: 100%;
  display: flex !important;
  flex-direction: column;
}

.logo {
  margin-top: 7%;
  margin-left: 18%;
}

.intro {
  margin: auto 5%;
  font-size: 21px;
}

.introPic {
  margin: auto;
  padding-top: 0;
  padding-bottom: 10%;
  min-width: 300px;
  min-height: 300px;
}

.artistsInfo {
  margin-top: 1%;
  margin-left: 5%;
  margin-bottom: 1%;
  margin-right: 5%;
  font-size: 22px;
}

.artistGroup {
  margin-bottom: 3%;
  margin: auto;

}

.backgroundborder {
  border-top: 50%;
  border-left: 0ch;
  border-right: 0ch;
  border-bottom: 0ch;
  border-style: ridge;
  border-color: #f6a12e;
  background-color: #212122;
}
.text-h4{
  color: #f6a12e;
}
</style>